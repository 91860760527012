function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.91 15.11a3.8 3.8 0 0 0-5.36 0 3.69 3.69 0 0 0-.91 1.49H9.92a2.15 2.15 0 1 1 0-4.3h5.87a4.31 4.31 0 0 0 0-8.61H8.42a3.76 3.76 0 0 0-.88-1.41 3.8 3.8 0 0 0-5.37 5.36l2.69 2.69 2.68-2.69c.43-.43.74-.96.92-1.54h7.33a1.9 1.9 0 1 1 0 3.8H9.92a4.56 4.56 0 1 0 0 9.11h5.73c.18.55.49 1.05.9 1.46l2.68 2.69 2.68-2.69a3.79 3.79 0 0 0 0-5.36zm-17-8.5a1.65 1.65 0 1 1 1.1-.47c-.3.3-.72.47-1.15.47zm14.32 12.83a1.65 1.65 0 1 1 0-3.3 1.65 1.65 0 0 1 0 3.3z"
      />
    </svg>
  );
}

export default Icon;
