function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16">
      <path
        fill="currentColor"
        d="M.33 5.5l-.2-.24A.33.33 0 010 5V2.33C0 2.15.15 2 .33 2h.05c.1-.4.34-.77.7-1L2.15.27c.27-.18.6-.28.92-.28H5.6c.33 0 .65.1.92.28L7.6.99c.36.24.6.6.7 1.01h.04c.19 0 .34.15.34.33V5c0 .1-.05.2-.13.26l1.75 2.9a.33.33 0 11-.58.34L8.33 6.2v4.47c.19 0 .34.15.34.33v2.67c0 .18-.15.33-.34.33H8.3A2.33 2.33 0 016 16H2.67a2.33 2.33 0 01-2.3-2H.33a.33.33 0 01-.33-.33V11c0-.18.15-.33.33-.33V5.5zM6.7 3.65a.66.66 0 00-.56-.32H2.54a.66.66 0 00-.6.97l.67 1.33c.11.23.34.37.6.37h2.25c.26 0 .48-.14.6-.37l.67-1.33c.1-.21.09-.45-.04-.65zm.98 6.14V5.8L7 6.47v3l.67.32zm-5.9 2.78c.03.03.78.76 2.56.76 1.79 0 2.54-.73 2.57-.76.1-.1.13-.26.06-.39l-.66-1.33a.34.34 0 00-.45-.15s-.62.3-1.52.3-1.5-.3-1.51-.3a.33.33 0 00-.45.15l-.67 1.33a.33.33 0 00.06.39zM1 9.79l.67-.33V6.47L1 5.8v4z"
      />
    </svg>
  );
}

export default Icon;
