function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M4.68 7.53A3.1 3.1 0 0 0 7.62 5.4h6.53V3.47H7.62a3.1 3.1 0 1 0-2.94 4.06zm.81 8.91V9.75H3.56v6.69a3.1 3.1 0 1 0 1.93 0zm13.02-9.06v6.69h1.93V7.38a3.1 3.1 0 1 0-1.93 0zm.96 8.91a3.1 3.1 0 0 0-2.94 2.13H9.84v1.93h6.69a3.1 3.1 0 0 0 5.46.86 3.1 3.1 0 0 0-2.52-4.92z"
      />
    </svg>
  );
}

export default Icon;
