function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.41 1.09H3.59v9.51h3.78V8.09l5.78 3.51-5.78 3.68v-2.52H3.59v4.34l11.55 5.81v-3.65h5.27zm-2 16.19h-3.3V6.94L7.44 3.06h11z"
      />
    </svg>
  );
}

export default Icon;
