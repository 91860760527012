function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 19">
      <path
        fill="currentColor"
        d="M1.5 0h20c.8 0 1.5.7 1.5 1.5v12c0 .8-.7 1.5-1.5 1.5h-13a1 1 0 0 0-.8.4l-1.9 2.5a1 1 0 0 1-1.6 0l-1.9-2.5a1 1 0 0 0-.8-.4C.7 15 0 14.3 0 13.5v-12C0 .7.7 0 1.5 0ZM5 5.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6.5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm6.5 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      />
    </svg>
  );
}

export default Icon;
