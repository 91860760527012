function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M17.93 6.44H6.79v11.14h11.14zM8.42 1.3h-6.1v6.2H4.6V3.58h3.82zm13.97 6.2V1.29H16.3v2.28h3.81V7.5zm-6.1 14.76h6.1v-6.08h-2.28v3.8h-3.82zM2.32 16.17v6.1H8.4v-2.28H4.6v-3.82zM14.38 1.3h-4.05v2.28h4.05zm0 18.69h-4.05v2.28h4.05zm-9.8-6.19V9.75H2.3v4.05zm17.81 0V9.75h-2.28v4.05z"
      />
    </svg>
  );
}

export default Icon;
