function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M18.37 13.33a6.77 6.77 0 1 1-6.86-6.76v3.09l8.07-4.41L11.51.83v3.1a9.4 9.4 0 1 0 9.5 9.4z"
      />
    </svg>
  );
}

export default Icon;
