function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M11.88 20.73a3.09 3.09 0 1 0 0-6.18 3.09 3.09 0 0 0 0 6.18zm10.41-17.8a3.79 3.79 0 0 0-5.33 0l-2.9 2.9a4.44 4.44 0 0 0-.52-.15A73.81 73.81 0 0 0 .86 4.83v13.25h6.36v-.44a4.7 4.7 0 0 1 9.38-.23l.29-.05a2.22 2.22 0 0 0 1.78-2.31l-.17-3.93a2.22 2.22 0 0 0-.5-1.25l-2.2-2.79-.23-.25 2.64-2.64a2 2 0 0 1 2.82 0l1.13 1.13 1.25-1.26zM16 10.54H2.9v-4c3.3-.05 6.6.2 9.86.71.52.11.99.4 1.32.82z"
      />
    </svg>
  );
}

export default Icon;
