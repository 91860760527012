function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        fill="currentColor"
        d="M28,48c4,0,8-3,8-6c1,0,1-1,1-1v-8c0,0,0-1-1-1V16c1,0,1-1,1-1s0-8,0-8s0-1-1-1c0-1-1-2-2-3s-4-3-6-3h-8c-2,0-5,2-6,3s-2,2-2,3c-1,0-1,1-1,1s0,8,0,8s0,1,1,1c0,0,0,16,0,16c-1,0-1,1-1,1v8c0,0,0,1,1,1c0,3,4,6,8,6H28zM14,30v-12l2,2v8L14,30zM32,28v-8l2-2v12L32,28zM28,10c3,0,3,1,3,2c0,2-1,4-2,5s-2,1-3,1h-4c-1,0-2,0-3-1s-2-3-2-5c0-1,0-2,3-2H28zM24,33c3,0,4-1,5-1s3,4,3,5s-3,3-8,3s-8-2-8-3s2-5,3-5S21,33,24,33z"
      />
    </svg>
  );
}

export default Icon;
