function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 14.49L7.37 9.74l1.9-1.84 2.85 2.92 7.12-6.37c-.207-.295-.434-.576-.68-.84-1.724-1.724-4.062-2.692-6.5-2.692-2.438 0-4.776.968-6.5 2.692-1.724 1.724-2.692 4.062-2.692 6.5 0 2.438.968 4.776 2.692 6.5l6.49 6.49 6.49-6.49c1.258-1.26 2.122-2.858 2.486-4.6.364-1.742.212-3.552-.436-5.21L12 14.49z"
      />
    </svg>
  );
}

export default Icon;
