function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.84 9.23h-8l-2 3.36H.71v1.83h1.63v5.11h2.42v-2h8.33v2h2.42v-5.78zM5.16 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm7.53 0a1 1 0 1 1 0-1.94 1 1 0 1 1 0 1.94zm8.69-7.4-2.17-3.67h-8l-1.71 3h4.24L17 13.26h2.44v2h2.43v-4.82h1.41V8.6zm-2.32 3.07a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
      />
    </svg>
  );
}

export default Icon;
