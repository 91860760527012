function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M9 7c-5 2-7 8-7 8s3 0 6 3C7 15 7 9 9 7Zm16 16c-2 2-8 2-11 1 3 3 3 6 3 6s6.07-2.17 8-7Zm-12-1c-3 0-3 3-6 3 0-3 3-3 3-6-2 3-8 1.34-8 11 9.66 0 8-6 11-8ZM12 6a9.83 9.83 0 0 0 0 14 9.83 9.83 0 0 0 14 0c3.9-3.9 4-18 4-18s-14.1.1-18 4Zm7 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
    </svg>
  );
}

export default Icon;
