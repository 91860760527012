function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="m11.7.3 10 10c.4.4.4 1 0 1.4l-10 10a1 1 0 0 1-1.4 0l-10-10a1 1 0 0 1 0-1.4l10-10a1 1 0 0 1 1.4 0zM10 5v8h2V5h-2zm-.5 12.5h3v-3h-3v3z"
      />
    </svg>
  );
}

export default Icon;
