function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.44 9a3.18 3.18 0 0 0-.74-2l-4.04-4.83-1.53 1.29 2.08 2.45a3.26 3.26 0 0 0 1 6.38 3.17 3.17 0 0 0 1.23-.29v5.38a1 1 0 0 1-2 0V16a3.5 3.5 0 0 0-3.5-3.5h-.79V2.59H2.08v18h12.08v-6.1H15A1.51 1.51 0 0 1 16.46 16v1.42a3 3 0 1 0 6 0v-8.2c-.04-.07-.02-.13-.02-.22zm-10.19 9.66H4V4.5h8.26zm6.92-8.36a1.28 1.28 0 1 1-.02-2.56 1.28 1.28 0 0 1 .02 2.56zM10.8 5.97H5.43v11.21h5.37z"
      />
    </svg>
  );
}

export default Icon;
