function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20">
      <g stroke="currentColor" fill="none" strokeWidth="2">
        <circle cx="10" cy="10" r="9" />
        <path d="M10 5v5l4 4" />
      </g>
    </svg>
  );
}

export default Icon;
