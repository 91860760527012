function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        fill="currentColor"
        d="M15 2V0H9v2H5v22h14V2h-4Zm2 20H7V4h10v18ZM16 5H8v16h8V5Z"
      />
    </svg>
  );
}

export default Icon;
