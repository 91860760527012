function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M4 19H2a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h2l3 4h5c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1H7l-3 4Zm20-2V7a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h5a2 2 0 0 0 2-2Zm-5-1h-2V8h2v8ZM8 14h3v-4H8v4Zm6-14h-2v5h2V0Zm0 19h-2v5h2v-5ZM8 1l-1.8.8 2 4.6 1.8-.8L8 1Zm2 17.4-1.8-.8-2 4.6L8 23l2-4.6Z"
      />
    </svg>
  );
}

export default Icon;
