function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15 2V0H9v2H5v22h14V2h-4Zm2 20H7V4h10v18Zm-1-3H8v2h8v-2Z"
      />
    </svg>
  );
}

export default Icon;
