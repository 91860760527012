function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeDasharray="0 0 0 2.49"
        strokeLinecap="round"
        strokeWidth="2"
        d="M30 12h-3M16 25h12v3h2V15.41l-.95-.94-4.8-7.79c-.66-.35-3.52-.8-8.25-.8M16 25H4v3H2V15.41l.95-.94 4.81-7.8c.6-.33 3.47-.8 8.24-.8M2 12h3"
      />
    </svg>
  );
}

export default Icon;
