function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16">
      <path
        fill="currentColor"
        d="M5 10.4a1.3 1.3 0 1 0 0-2.6 1.3 1.3 0 0 0 0 2.6ZM5 13v3H1V8H0V6h2l3-6h12l3 6h2v2h-1v8h-4v-3H5Zm12-2.6a1.3 1.3 0 1 0 0-2.6 1.3 1.3 0 0 0 0 2.6Z"
      />
    </svg>
  );
}

export default Icon;
