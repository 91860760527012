function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
      <path
        d="M7 16a3 3 0 1 1 0-6 3 3 0 0 1 0 6ZM0 8v10h14V8h-2V5A5 5 0 0 0 2 5h2a3 3 0 1 1 6 0v3H0Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
