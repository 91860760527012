function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="M11 1a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm3 12.5V15h2v-3.98l.5-.02v-1h-.83L14 7H8l-1.67 3H5.5v1l.5.02V15h2v-1.5h6zm.75-2a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0zm-6 0a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0z"
      />
    </svg>
  );
}

export default Icon;
