function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <path
        d="m.1 8 4-7.5a1 1 0 0 1 1.7 0l4 7.4a1 1 0 0 1-.7 1.4H.9A1 1 0 0 1 .1 8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
