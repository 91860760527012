function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15.3 5.53A12.54 12.54 0 0 0 4.44 11.8L.59 18.47H12.5v-4.53H9.6l4.06-6.68 4.25 6.68H15v4.53h8.46V5.53z"
      />
    </svg>
  );
}

export default Icon;
