function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M18.66 3.64a9.08 9.08 0 0 0-12.85 0 9 9 0 0 0-2.55 5.28h6.83v-3.2l7.35 4.48-7.35 4.67v-3.19H3.3a9 9 0 0 0 2.51 4.8l6.42 6.42 6.43-6.42a9.09 9.09 0 0 0 0-12.84z"
      />
    </svg>
  );
}

export default Icon;
