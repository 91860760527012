function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M0 15.9V20h4.1L16.5 7.6l-4-4.1L0 15.9Zm19.6-13L17.1.4a1 1 0 0 0-1.6 0l-2 2 4 4.2 2.1-2.1a1 1 0 0 0 0-1.6Z"
      />
    </svg>
  );
}

export default Icon;
