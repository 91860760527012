import clsx from 'clsx';
import { forwardRef, HTMLProps, Ref } from 'react';
import { env } from 'src/api/hosts';
import { IconName, ICONS, isIconName } from 'src/components/common/Icon';
import './Icon.scss';

interface FallbackProps extends HTMLProps<HTMLSpanElement> {
  className?: string;
  iconName?: string;
}

function FallbackIcon({
  className,
  iconName,
  ...otherSpanProps
}: FallbackProps) {
  return (
    <i {...otherSpanProps} className={clsx(['the-icon', className])}>
      {env === 'prd' ?
        <ICONS.CircleOutline />
      : `[ICON MISSING${iconName ? `: ${iconName}` : ''}]`}
    </i>
  );
}

interface Props extends HTMLProps<HTMLSpanElement> {
  name: IconName;
  className?: string;
  hideIfEmpty?: boolean;
}

function Icon(
  { name, className, hideIfEmpty, ...otherSpanProps }: Props,
  ref: Ref<HTMLSpanElement>,
) {
  if (!isIconName(name)) {
    return hideIfEmpty ? null : (
        <FallbackIcon
          {...otherSpanProps}
          className={className}
          iconName={name}
        />
      );
  }

  const IconComponent = ICONS[name];

  return (
    <i {...otherSpanProps} ref={ref} className={clsx(['the-icon', className])}>
      <IconComponent />
    </i>
  );
}

export default forwardRef(Icon);
