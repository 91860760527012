function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M1.37 1.65v7.08h3.55a7.08 7.08 0 1 0 14.16 0h3.55V1.65zM12 13.41a4.67 4.67 0 0 1-4.68-4.68h9.36A4.67 4.67 0 0 1 12 13.41zm1.18 4.63h-2.37v4.82h2.37zm-7.26-2.02-1.67-1.68-3.41 3.41 1.67 1.68zm13.83-1.68-1.67 1.68 3.4 3.4 1.68-1.67z"
      />
    </svg>
  );
}

export default Icon;
