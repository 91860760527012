function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="m1-7h-2v14h2ZM8,0a8,8 0 1,0-16,0a8,8 0 1,0 16,0M-6,-0a6,6 0 1,1 12,0a6,6 0 1,1-12,0"
        transform="translate(8 8) rotate(45)"
      />
    </svg>
  );
}

export default Icon;
