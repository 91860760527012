function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15.4 1.9a4.8 4.8 0 1 0-6.79 6.79l3.39 3.4 3.4-3.4a4.81 4.81 0 0 0 0-6.79zM12 7.38a2.09 2.09 0 1 1 2.09-2.08A2.08 2.08 0 0 1 12 7.38zm4.33 4-1.36 1.26 6.17 6.64 1.35-1.26zm-7.17 1.26L7.8 11.38l-6.29 6.77 1.36 1.26zm3.79 1.53h-1.89v3.5h1.89zm0 6.07h-1.89v3.5h1.89z"
      />
    </svg>
  );
}

export default Icon;
