function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14">
      <path
        fill="none"
        stroke="currentColor"
        d="M9.5.5c2 1 2 2 2 3v8c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6-.4-1-1-1H7m4.5-4c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.4 1-1z"
      />
      <path
        fill="currentColor"
        d="M0 13V2.5c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2V13c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1zm1-8h6V2.5c0-.6-.4-1-1-1H2c-.6 0-1 .4-1 1V5z"
      />
    </svg>
  );
}

export default Icon;
