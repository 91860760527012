function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12 15.85a3.72 3.72 0 1 0 0-7.44 3.72 3.72 0 0 0 0 7.44zm6.89-5.17V5.24h-5.25V2.15h8.33v8.53zm-16.86 0V2.15h8.33v3.09H5.11v5.44zm0 11.42v-8.13h3.08v5.05h5.25v3.08zm11.61 0v-3.08h5.25v-5.05h3.08v8.13z"
      />
    </svg>
  );
}

export default Icon;
