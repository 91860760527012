function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m14 15 1 1v1l7 7 2-2-7-7h-1l-1-1ZM0 9a9 9 0 1 0 9-9 9 9 0 0 0-9 9m3 0a6 6 0 1 1 6 6 6 6 0 0 1-6-6"
      />
    </svg>
  );
}

export default Icon;
