function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M0 0h12v5H8l6 6-3 3-6-6v4H0V0zm32 0v12h-5V8l-6 6-3-3 6-6h-4V0h12zm0 32H20v-5h4l-6-6 3-3 6 6v-4h5v12zM0 32V20h5v4l6-6 3 3-6 6h4v5H0z"
      />
    </svg>
  );
}

export default Icon;
