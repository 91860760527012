function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M23.28 17.19h-1.61V4.67H5.15V3.06h18.13zM.7 6.71v14.94h18.93V6.71zm5.61 1.41a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-3.11 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM18 20H2.31v-8.56H18zM6.57 18.77l-3.03-3.04 3.03-3.03 1.12 1.11-1.92 1.92 1.92 1.92zm3.66.07H8.62l1.69-6.22h1.61zm3.53-.07-1.11-1.12 1.92-1.92-1.92-1.92 1.11-1.11 3.04 3.03z"
      />
    </svg>
  );
}

export default Icon;
