function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        fill="currentColor"
        d="M10.4 16.1l.5.6.1-.7.1-.7a6.3 6.3 0 0 1 5.1-4.5l.8-.1-.7-.5A6 6 0 0 1 14 3.9l.2-.7.3-.8-.8.3a6 6 0 0 1-6.6-1.3l-.8-1-.2-.2-.1.9-.1.7A6.4 6.4 0 0 1 .8 6.3l-.8.1.7.5c1 .7 1.7 1.7 2.1 2.8a7 7 0 0 1 .3 3.5l-.2.8-.3.8.8-.3c1.2-.5 2.5-.6 3.8-.3 1.2.2 2.4.9 3.2 1.9zM9 5v5H8V5h1zm0 6v1H8v-1h1z"
      />
    </svg>
  );
}

export default Icon;
