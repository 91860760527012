function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16.29 2H8L2.15 7.86v8.28L8 22h8.28l5.86-5.86V7.86zm-5.57 14.75h-3.4v-9.5h3.4zm6.26 0h-3.39v-9.5H17z"
      />
    </svg>
  );
}

export default Icon;
