function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12 1.69A10.3 10.3 0 0 0 1.69 12v10.31H12a10.31 10.31 0 0 0 0-20.62zM12.91 18h-2.64V9.65h2.64zm0-9.93h-2.64v-2.2h2.64z"
      />
    </svg>
  );
}

export default Icon;
