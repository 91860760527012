function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.31 5.4H15.4l-4.31 4.32-3.3-5.58h-6.1v12.72h6.47v3h3.7V16h10.45zm-14.15 9a1.3 1.3 0 1 1 1.3-1.29 1.29 1.29 0 0 1-1.3 1.31zm12.22-4.49h-6.95l2.8-2.78h4.15z"
      />
    </svg>
  );
}

export default Icon;
