function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 21">
      <path
        d="M0 10 9 0l9 10h-6v6H6v-6H0Zm0 8h18v3H0v-3Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
