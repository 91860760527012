function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M11.39 5.77h-1V2L1.91 7.15l8.51 5.41V8.94h1a6.18 6.18 0 0 1 6.18 6.18V22h3.17v-6.9a9.36 9.36 0 0 0-9.38-9.33z"
      />
    </svg>
  );
}

export default Icon;
