function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.12 1.68.68 9.9v3.7l3.39-2.46v10.64h6.66v-5.96H7.37l4.7-7.74 4.92 7.74h-3.36v5.96h6.39V11.09l3.39 2.45V9.82z"
      />
    </svg>
  );
}

export default Icon;
