function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <circle
        cx="12"
        cy="12"
        r="8"
        fill="none"
        stroke="currentColor"
        strokeDasharray="5 4 10 4 18 5"
        strokeWidth="2"
      />
      <circle cx="12" cy="12" r="4" fill="currentColor" />
    </svg>
  );
}

export default Icon;
