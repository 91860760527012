function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16">
      <path
        d="M20.2 6 17 0H5L2 6H0v2h1v8h4v-3h12v3h4V8h1V6h-1.8ZM7 8.7 3 10V8h4v.7Zm6 1.3H9V8h4v2Zm6 0-4-1.3V8h4v2Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
