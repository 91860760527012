function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.12 18.44H1.79v3.96h20.33zm-11.41-2.82v-4.51H7.82l4.04-6.65 4.23 6.65H13.2v4.51h8.92V1.39H1.79v14.23z"
      />
    </svg>
  );
}

export default Icon;
