function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        fill="currentColor"
        d="M10.6 3.7a1 1 0 0 0-.9-.7H2.3a1 1 0 0 0-.9.7L0 7.8v5.5c0 .4.3.7.7.7h.7c.3 0 .6-.3.6-.7v-.7h8v.7c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V7.8l-1.5-4.1zm-8.3 6.9a1 1 0 0 1-1-1c0-.6.4-1 1-1s1 .5 1 1-.4 1-1 1zm7.4 0a1 1 0 0 1-1-1c0-.6.4-1 1-1s1 .5 1 1-.5 1-1 1zM1.3 7.1l1-3.1h7.3l1 3.1H1.3zm13.7-2c.6 0 1-.5 1-1.1 0-.5-.4-1-1-1s-1 .5-1 1.1c0 .5.4 1 1 1zm1.5.4h-3c-.3 0-.5.3-.5.6l.2 3.2c0 .3.2.5.5.5l.3 3.8c0 .2.2.4.4.4h1.2c.2 0 .4-.2.4-.4l.3-3.8c.3 0 .5-.2.5-.5l.2-3.2c0-.3-.2-.6-.5-.6z"
      />
    </svg>
  );
}

export default Icon;
