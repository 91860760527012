function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m3.63 13.26 2.06 2.06a8.68 8.68 0 0 1 2.06-1.53l1.38-3.61a11.54 11.54 0 0 0-5.5 3.08zm7.26-7.66a15.75 15.75 0 0 0-10.3 4.62l2.06 2.06a12.82 12.82 0 0 1 7.07-3.62zM21 12.28l2-2.06A15.68 15.68 0 0 0 15.37 6l1.11-2.89-2-.77-1.29 3.29-1.09 2.86-.53 1.39-1.16 3-.65 1.69-1.28 3.37-.62 1.62 2 .77 1.17-3.06a4.15 4.15 0 0 1 3.8 1.13l2.06-2.06a7.06 7.06 0 0 0-4.71-2.07l.55-1.43a8.6 8.6 0 0 1 5.18 2.48L20 13.26a11.47 11.47 0 0 0-6.17-3.21l.5-1.31A12.81 12.81 0 0 1 21 12.28z"
      />
    </svg>
  );
}

export default Icon;
