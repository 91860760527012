function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m21.45 10-3.4-5.76H5.9L2.55 10H.61v2h1.52v7.81H5.8v-3h12.62v3h3.68V12h1.29v-2zM5.8 14.41a1.29 1.29 0 1 1 1.29-1.29 1.28 1.28 0 0 1-1.29 1.29zm6.2-1.05a3.81 3.81 0 1 1 0-7.62 3.81 3.81 0 0 1 0 7.62zm6.42 1.05a1.29 1.29 0 1 1 1.29-1.29 1.28 1.28 0 0 1-1.29 1.29zm-4.11-5.45H12.6V7.25h-1.2v1.71H9.69v1.19h1.71v1.72h1.2v-1.72h1.71z"
      />
    </svg>
  );
}

export default Icon;
