function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
      <path
        d="M0 25V5h3v17h17v3H0Zm25-6H6V0h19v19ZM21 8h-4V4h-3v4h-4v3h4v4h3v-4h4V8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
