function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="M10.15 1.07a.96.96 0 0 1 1.7 0l10.03 18.9c.35.67-.12 1.49-.85 1.49H.97c-.73 0-1.2-.82-.85-1.48L10.15 1.07zM10 6v8h2V6h-2zm-.5 12.5h3v-3h-3v3z"
      />
    </svg>
  );
}

export default Icon;
