function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m16.92 18.67-1.59-1.57a7.2 7.2 0 0 0 0-10.22l1.59-1.56a9.43 9.43 0 0 1 2.06 3.06 9.35 9.35 0 0 1 0 7.22 9.43 9.43 0 0 1-2.06 3.06zm-3.31-3.26-1.59-1.57a2.56 2.56 0 0 0 .76-1.82c0-.68-.27-1.33-.76-1.82l1.59-1.56a4.76 4.76 0 0 1 1.41 3.38c0 1.27-.5 2.49-1.41 3.39zm-6.5.36A22.85 22.85 0 0 1 6.8 12c0-1.31.11-2.62.34-3.91l2.29-.59a1.08 1.08 0 0 0 .76-.76 23.96 23.96 0 0 1 1.67-3.95 1.22 1.22 0 0 0-.56-1.65 1.27 1.27 0 0 0-.6-.13l-2.5.1a2.81 2.81 0 0 0-1.51.52c-.44.31-.78.75-.98 1.26a25.1 25.1 0 0 0 0 18.25c.2.5.54.93.98 1.25s.97.49 1.51.5l2.4.12a1.25 1.25 0 0 0 .89-.3 1.23 1.23 0 0 0 .41-.85 1.28 1.28 0 0 0-.14-.63 19.36 19.36 0 0 1-1.62-4.09 1.07 1.07 0 0 0-.77-.78z"
      />
    </svg>
  );
}

export default Icon;
