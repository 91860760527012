function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.26 15.64a2.72 2.72 0 0 1-2.72-2.72V7.5h2.68L8 .5l-4.45 7h2.66v5.42a6.05 6.05 0 0 0 6.05 6A2.74 2.74 0 0 1 15 21.7v1.52h3.33V21.7a6.07 6.07 0 0 0-6.07-6.06zM14.43 14a2.62 2.62 0 1 0 0-5.24 2.62 2.62 0 0 0 0 5.24z"
      />
    </svg>
  );
}

export default Icon;
