function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16.9 2.7 15 2 8.4 19.2l2 .7 1-3h7v3h3.8V12h1.3v-2h-2L18.1 4h-1.8l.6-1.4zm-11 1.4H12L7.2 17H5.8v3H2V12H.5v-2h2L5.9 4zm-.1 10.3a1.3 1.3 0 1 1 0-2.6 1.3 1.3 0 0 1 0 2.6zm12.7 0a1.3 1.3 0 1 1 1.3-1.3 1.3 1.3 0 0 1-1.3 1.3z"
      />
    </svg>
  );
}

export default Icon;
