function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25">
      <path
        d="M0 3h7V1c0-.6.4-1 1-1h5c.6 0 1 .4 1 1v2h7v3H0V3Zm2 19a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3V8H2v14Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
