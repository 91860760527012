function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.16 18.28v-7.37a1.7 1.7 0 0 1 1.69-1.7 1.71 1.71 0 0 1 1.71 1.7v7.37zm1.74-9.84a1.72 1.72 0 1 0 0-3.44 1.72 1.72 0 0 0 0 3.44zm-3.15 1.73h-1.6l-2.8-4.74h-10l-2.76 4.74H0v1.68h1.25v6.43h3v-2.45h10.41v2.45h3v-6.43h1.09zM4.27 13.83a1.06 1.06 0 1 1 0-2.12 1.06 1.06 0 0 1 0 2.12zm10.39 0a1.06 1.06 0 1 1 0-2.12 1.06 1.06 0 0 1 0 2.12z"
      />
    </svg>
  );
}

export default Icon;
