function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.33 6.33a6.64 6.64 0 0 0-9.4 0 6.65 6.65 0 0 0 0 9.41l4.7 4.7 4.7-4.7a6.65 6.65 0 0 0 0-9.41zm-4.7 7.59a2.89 2.89 0 1 1 0-5.78 2.89 2.89 0 0 1 0 5.78zM.92 20.34l4.26-7.68L.92 4.49H4.2l4.26 8.17-4.26 7.68z"
      />
    </svg>
  );
}

export default Icon;
