function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14">
      <path
        fill="currentColor"
        d="M17 12v2h4V7h1V5h-2l-3-5H5L2 5H0v2h1v7h4v-2ZM5 9.3A1.3 1.3 0 1 1 6.3 8 1.3 1.3 0 0 1 5 9.3Zm12 0A1.3 1.3 0 1 1 18.3 8 1.3 1.3 0 0 1 17 9.3Zm-6 .7a1 1 0 0 1 0-2 1 1 0 0 1 0 2Zm.6-3.1v.7h-1.2V6h.8a.9.9 0 1 0 0-1.8.8.8 0 0 0-.9.8H9a2.1 2.1 0 0 1 2.2-2 2.1 2.1 0 0 1 2.2 2 2.1 2.1 0 0 1-1.8 1.9Z"
      />
    </svg>
  );
}

export default Icon;
