function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M18.34 3.57V1h-4.26v2.57H9.92V1H5.66v2.57H1v19h22v-19zM20.41 20H3.59V9.05h16.82zM9.93 11.19H5.66v4.27h4.27z"
      />
    </svg>
  );
}

export default Icon;
