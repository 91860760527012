function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 15.18L6.84 9.92l2.1-2 3.16 3.24 7.2-6.44L12 .84 1.9 6.13c0 5.73 2.87 11.23 8 15.33l2.1 1.7 2.12-1.7c5.11-4.1 8-9.6 8-15.33L12 15.18z"
      />
    </svg>
  );
}

export default Icon;
