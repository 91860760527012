function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M17.09 22.95a3.79 3.79 0 1 0 0-7.58 3.79 3.79 0 0 0 0 7.58zm-2.67-19.2h-2.9a4.23 4.23 0 1 0 0 2.87h2.9a2.09 2.09 0 1 1 0 4.18h-4.06a5 5 0 1 0 0 9.93h1.45v-2.87h-1.45a2.1 2.1 0 1 1 0-4.19h4.06a5 5 0 0 0 0-9.92zM7.57 6.54a1.36 1.36 0 1 1-.02-2.72 1.36 1.36 0 0 1 .02 2.72z"
      />
    </svg>
  );
}

export default Icon;
