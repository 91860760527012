function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m12 20.18-2.66-2.66-1.73 1.73L12 23.64l4.39-4.39-1.73-1.73zM4.85 7.72.46 12.1l4.39 4.39 1.73-1.73-2.66-2.66 2.66-2.65zm2.76-2.77 1.73 1.73L12 4.03l2.66 2.65 1.73-1.73L12 .57zm11.54 2.77-1.73 1.73 2.66 2.65-2.66 2.66 1.73 1.73 4.39-4.39zm-8.22.38v2.74H8.19v2.45h2.74v2.74h2.45v-2.74h2.74v-2.45h-2.74V8.1z"
      />
    </svg>
  );
}

export default Icon;
