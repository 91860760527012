function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16.27 7.88V4.62a2.1 2.1 0 0 0-.19-.85 8.85 8.85 0 0 0-1.23-2 2 2 0 0 0-1.54-.71h-2.75A2.06 2.06 0 0 0 9 1.8a9.65 9.65 0 0 0-1.22 2 2.09 2.09 0 0 0-.18.85v3.23L5.35 9.5l.77 1.09 1.49-1.06v11.3A1.82 1.82 0 0 0 9 22.61c.96.2 1.94.3 2.92.29.97.01 1.94-.08 2.89-.28a1.85 1.85 0 0 0 1.44-1.8V9.53l1.46 1.05.79-1.09zm-1.68 1.33H9.23L9 6.32A8.31 8.31 0 0 1 11.86 6H12a8.35 8.35 0 0 1 2.92.34z"
      />
    </svg>
  );
}

export default Icon;
