function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M7.08 21.38H1.06L7.37 12 1.06 2.63h6.02L13.39 12zm9.55 0h-6.02l6.3-9.38-6.3-9.37h6.02l6.3 9.37z"
      />
    </svg>
  );
}

export default Icon;
