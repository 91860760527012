function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M19.79 14.14h2.66V9.71h-2.66A1 1 0 0 1 19.07 8L21 6.1 17.83 3 16 4.85a1 1 0 0 1-1.74-.72V1.47H9.79v2.66a1 1 0 0 1-1.74.72L6.17 3 3 6.1 4.93 8a1 1 0 0 1-.72 1.73H1.55v4.43h2.66a1 1 0 0 1 .72 1.73L3 17.75l3.13 3.13L8.05 19a1 1 0 0 1 1.74.71v2.67h4.42v-2.66A1 1 0 0 1 16 19l1.88 1.88L21 17.75l-1.89-1.88a1 1 0 0 1 .68-1.73zM12 16.38a4.46 4.46 0 1 1-.02-8.92 4.46 4.46 0 0 1 .02 8.92z"
      />
    </svg>
  );
}

export default Icon;
