function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        stroke="0"
        d="M13 18v-8H9v8h4Zm0-12a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm9 5a11 11 0 1 1-22 0 11 11 0 0 1 22 0Z"
      />
    </svg>
  );
}

export default Icon;
