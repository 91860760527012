function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M0 16l4.7-6.1 1.4 1.4L0 16M10.5 0a2.3 2.3 0 00-.4 2.7 1 1 0 01-.4 1.2L8 5.5a.8.8 0 01-1.2.1 3.6 3.6 0 00-4.3.5l7.4 7.4a3.6 3.6 0 00.5-4.3.8.8 0 01.1-1.2l1.6-1.7a1 1 0 011.2-.4 2.3 2.3 0 002.7-.4z"
      />
    </svg>
  );
}

export default Icon;
