function Map() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m24 16-8 6-8-3-8 5V7l8-5 8 3 8-5v16ZM8 17a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm8-11a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM8.5 4a.5.5 0 1 0-1 0v5a.5.5 0 1 0 1 0V4Zm8 10a.5.5 0 1 0-1 0v6a.5.5 0 1 0 1 0v-6ZM5 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm14-3a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 10c-1.657 0-3 1.343-3 3s3 5 3 5 3-3.343 3-5-1.343-3-3-3Zm14-3c-1.657 0-3 1.343-3 3s3 5 3 5 3-3.343 3-5-1.343-3-3-3Z"
      />
    </svg>
  );
}

export default Map;
