function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.66 4.37H7.79A1.55 1.55 0 0 0 6.58 5l-5.06 6.14a1.57 1.57 0 0 0 0 2l5.06 6.19a1.57 1.57 0 0 0 1.21.57h13.87a.89.89 0 0 0 .9-.89V5.27a.9.9 0 0 0-.9-.9zM18.72 15.3l-1.56 1.56L14 13.68l-3.18 3.18-1.56-1.56 3.17-3.17L9.26 9l1.55-1.6L14 10.57l3.16-3.17L18.72 9l-3.18 3.18z"
      />
    </svg>
  );
}

export default Icon;
