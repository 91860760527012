function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.4 9.78h-6.3l-3.93 3.94-3-5.09H2.6v11.6h5.9V23h3.38v-3.55h9.52zM8.5 18a1.18 1.18 0 1 1 0-2.36 1.18 1.18 0 0 1 0 2.36zm11.14-4.12H13.3l2.56-2.53h3.78zm-3.07-9.3a1.97 1.97 0 1 0 0-3.94 1.97 1.97 0 0 0 0 3.94zm-2.06 3.95-3 2.83 1.06-4.16a2.81 2.81 0 0 1 3.4-2 2.81 2.81 0 0 1 2 3.4z"
      />
    </svg>
  );
}

export default Icon;
