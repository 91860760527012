function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16.16 4.27a5.65 5.65 0 0 0-8 0L7 3.05a7.38 7.38 0 0 1 10.43 0zm-2.57 2.57a2 2 0 0 0-2.84 0L9.53 5.62a3.68 3.68 0 0 1 2.64-1.1 3.72 3.72 0 0 1 2.65 1.1zm4.01 1.64H6.34a3.23 3.23 0 0 0-3.23 3.23v8.23a3.22 3.22 0 0 0 3.23 3.23H17.6a3.22 3.22 0 0 0 3.23-3.23v-8.23a3.23 3.23 0 0 0-3.23-3.23zM8.06 19.29v-1.73l2.81-2.17a2.17 2.17 0 1 1 2.2 0l2.81 2.17v1.73z"
      />
    </svg>
  );
}

export default Icon;
