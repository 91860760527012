function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16.5 1.14h-9L1.14 7.5v9l6.36 6.36h9l6.36-6.36v-9zm.22 14A3.89 3.89 0 0 1 12.84 19h-.47a3.85 3.85 0 0 1-3.22-1.72l-3.47-5.15a.89.89 0 0 1 .17-1.19.93.93 0 0 1 .53-.17.84.84 0 0 1 .71.38l1.46 2v-7a.78.78 0 1 1 1.55 0v5a.32.32 0 0 0 .33.32.32.32 0 0 0 .32-.32V5a.78.78 0 0 1 .78-.78h.05a.8.8 0 0 1 .73.81v6.17a.32.32 0 0 0 .32.32.32.32 0 0 0 .37-.32V5.68a.77.77 0 0 1 .77-.77h.06a.79.79 0 0 1 .72.8v5.51a.33.33 0 0 0 .33.33.32.32 0 0 0 .32-.33v-4a.79.79 0 0 1 .73-.8.77.77 0 0 1 .78.77z"
      />
    </svg>
  );
}

export default Icon;
