function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M27 26v3h4V15l-2-2h2v-2h-3l-3-5C23.5 4.5 8.5 4.5 7 6l-3 5H1v2h2l-2 2v14h4v-3h22ZM9 18H3v-2h6v2Zm20 0h-6v-2h6v2Zm-8-9 2 2-5 5 5 5-2 2-5-5-5 5-2-2 5-5-5-5 2-2 5 5 5-5Z" />
    </svg>
  );
}

export default Icon;
