function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15.92 7H7.76A1.66 1.66 0 0 0 6.1 8.65v5.63a1.66 1.66 0 0 0 1.66 1.66h4.18l3.47 2v-2h.51a1.66 1.66 0 0 0 1.66-1.66V8.65A1.66 1.66 0 0 0 15.92 7zm-6.69 5.19a.68.68 0 1 1 .68-.68.67.67 0 0 1-.68.68zm2.6 0a.68.68 0 1 1 .68-.68.67.67 0 0 1-.68.68zm2.61 0a.68.68 0 1 1 0-1.36.68.68 0 0 1 0 1.36zM1 10.17V10A11.14 11.14 0 0 1 10.47.92h.17v2.31h-.12a8.9 8.9 0 0 0-7.16 6.8v.12zM10.47 23A11.16 11.16 0 0 1 .9 13v-.16h2.3v.13a8.86 8.86 0 0 0 7.3 7.67h.12V23zm10.15-12.83v-.12a8.9 8.9 0 0 0-7.16-6.8h-.12V.89h.17A11.16 11.16 0 0 1 22.94 10v.17zm-7.3 10.5h.12a8.86 8.86 0 0 0 7.3-7.67v-.13h2.32v.16a11.16 11.16 0 0 1-9.57 10h-.17z"
      />
    </svg>
  );
}

export default Icon;
