function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M3 0v2c0 3-2 4-2 7 0 2 1 4 3 5h8c2-1 3-3 3-5 0-3-2-4-2-7V0"
      />
      <path
        fill="currentColor"
        d="M4.2 16H2v-3h2.2v3zm3.3-2H5.3v2h2.2v-2zm6.5-1h-2.2v3H14v-3zm-3.3 1H8.5v2h2.2v-2zM9 9H7v2h2V9zm0-6H7v5h2V3z"
      />
    </svg>
  );
}

export default Icon;
