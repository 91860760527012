function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor">
        <path strokeWidth="1.3" d="M12 0v24m12-12H0" />
        <circle strokeWidth="1.8" cx="12" cy="12" r="8" />
      </g>
      <circle cx="12" cy="12" r="4" fill="currentColor" />
    </svg>
  );
}

export default Icon;
