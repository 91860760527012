function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 32 32"
    >
      <circle cx="24" cy="6" r="4" />
      <path d="m12 15-9 3v3l2-.8V30h8v-5h3v5h2v-9.9l2 .9v-3l-8-3Zm-2 13H7v-2h3v2Zm0-6H7v-2h3v2Zm6 0h-3v-2h3v2Zm8-11c-3.3 0-6 .9-6 2v2h12v-2c0-1.1-2.7-2-6-2Z" />
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
      >
        <path
          strokeDasharray="0 0 2.2 4.4"
          strokeWidth="3"
          d="M21 29c6-2 8-7 7-12"
        />
        <path
          strokeDasharray="0 0 2.1 3"
          strokeWidth="2"
          d="M18 8A41 41 0 0 0 1 4"
        />
      </g>
    </svg>
  );
}

export default Icon;
