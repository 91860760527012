function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M13.39 15.91 7.89 15l-2.74-1.68A6.17 6.17 0 0 0 2 12.41H.79v7.38h5.53a2.57 2.57 0 0 1 5.14-.13 16.58 16.58 0 0 0 2.22-.26l.69-.13a.66.66 0 0 0 .53-.65v-.92a1.8 1.8 0 0 0-1.51-1.79zm-4.44 5.43a1.56 1.56 0 1 0 0-3.12 1.56 1.56 0 0 0 0 3.12zM10.61 7l5.5-.89 2.74-1.69A6.08 6.08 0 0 1 22 3.51h1.18v7.37h-5.5a2.57 2.57 0 0 0-5.14-.12 18.4 18.4 0 0 1-2.22-.26l-.69-.13a.66.66 0 0 1-.53-.65v-.93A1.81 1.81 0 0 1 10.61 7zm4.44 5.43a1.56 1.56 0 1 0 0-3.12 1.56 1.56 0 0 0 0 3.12zM2.54 10.07H.82V3.5h7.44v1.72H2.54zm20.63 9.71H16.6v-1.72h4.86v-4.85h1.71z"
      />
    </svg>
  );
}

export default Icon;
