function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M8.58 19.81a3.25 3.25 0 0 0 6.5 0zm8.85-6.55h-.33A6.09 6.09 0 0 1 13 2.72v-.63a1.12 1.12 0 0 0-2.24 0v.8a5.35 5.35 0 0 0-4.3 5.24v2.73a11.75 11.75 0 0 1-1.58 5.9l-1.07 1.86h16.05l-1.08-1.84a11.88 11.88 0 0 1-1.35-3.52zm2.77-5.12h1.06V6.38H20.2a.4.4 0 0 1-.28-.69l.75-.75-1.25-1.24-.75.74a.4.4 0 0 1-.67-.28V3.1h-1.77v1.06a.41.41 0 0 1-.69.29l-.75-.75-1.25 1.24.75.75a.4.4 0 0 1-.28.69H13v1.76h1a.4.4 0 0 1 .28.69l-.75.75 1.25 1.24.75-.75a.41.41 0 0 1 .69.29v1.05H18v-1.05a.41.41 0 0 1 .69-.29l.75.75 1.25-1.24-.75-.75a.4.4 0 0 1 .26-.69zM17.1 9a1.77 1.77 0 1 1 0-3.54 1.77 1.77 0 0 1 0 3.54z"
      />
    </svg>
  );
}

export default Icon;
