function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14">
      <path
        fill="currentColor"
        d="M17 12v2h4V7h1V5h-2l-3-5H5L2 5H0v2h1v7h4v-2zm-3-6l-1 1-2-2-2 2-1-1 2-2-2-2 1-1 2 2 2-2 1 1-2 2zM5 9.3A1.3 1.3 0 116.3 8 1.3 1.3 0 015 9.3zm12 0A1.3 1.3 0 1118.3 8 1.3 1.3 0 0117 9.3z"
      />
    </svg>
  );
}

export default Icon;
