function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M11.71 12.08a2.35 2.35 0 1 0 0-4.7 2.35 2.35 0 0 0 0 4.7zm0-6.59a2.35 2.35 0 1 0 0-4.7 2.35 2.35 0 0 0 0 4.7zm-5.57 8.6 5.7 8.97 5.45-8.97z"
      />
    </svg>
  );
}

export default Icon;
