function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        fill="currentColor"
        d="M14.4 3c-1.9-.2-3.7-1-5-2.3L9 .4C8.9.2 8.7.2 8.5.2s-.3 0-.5.2l-.3.3a7.5 7.5 0 0 1-5 2.3c-.4.1-.7.4-.7.8v2.1c0 4.4 2.4 8.4 6.2 10.6l.4.1.4-.1c3.8-2.1 6.2-6.2 6.2-10.6V3.8a1 1 0 0 0-.8-.8zm-2.7 3.9l-3.2 3.5-.5.2a.8.8 0 0 1-.5-.2L5.6 8.6c-.3-.3-.3-.7 0-1 .3-.3.7-.3 1 0l1.3 1.3L10.6 6c.3-.3.7-.3 1-.1.3.3.3.7.1 1z"
      />
    </svg>
  );
}

export default Icon;
