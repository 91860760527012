function MapWithArrowUp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <clipPath id="a">
          <path d="M10 5v9h4V0h10v24H0V5h10z" />
        </clipPath>
      </defs>
      <g fill="currentColor">
        <path d="M12 0 8 4h3v9h2V4h3l-4-4z" />
        <path
          d="m24 16-8 6-8-3-8 5V10l8-5 8 3 8-8v16Zm-6-8.1c-1.2 1.1-1 2.1 0 4 1.1 2 2 3.1 2 3.1s1-1.3 2-3.1 1.2-2.9 0-4a2.7 2.7 0 0 0-4 0Zm-15 3c-1.2 1.1-1 2.1 0 4 1.1 2 2 3.1 2 3.1s1-1.3 2-3.1 1.2-2.9 0-4a2.7 2.7 0 0 0-4 0ZM20 9a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3.5-3V7a.5.5 0 1 0-1 0v2a.5.5 0 1 0 1 0Zm-.3 7.5.1-.2.2-.3c0-.1 0-.3-.2-.4a.5.5 0 0 0-.5 0h-.2v.2l-.1.2.1.3c.1.2.3.2.4.2h.2Zm8.3 2.5v-8a.5.5 0 1 0-1 0v8a.5.5 0 1 0 1 0Z"
          clipPath="url(#a)"
        />
      </g>
    </svg>
  );
}

export default MapWithArrowUp;
