function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <g fill="currentColor">
        <circle cx="19" cy="11" r="3" />
        <circle cx="3" cy="11" r="3" />
        <circle cx="11" cy="11" r="3" />
      </g>
    </svg>
  );
}

export default Icon;
