function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21 10.64a28.25 28.25 0 0 0-7.77-1.37v11.31c2.37.12 4.7.65 6.89 1.57l2.22-11.07zm-10 9.95V9.28a28.18 28.18 0 0 0-7.59 1.36l-1.33.44 2.23 11.07a20.42 20.42 0 0 1 6.76-1.56zm5.83-18.74a5.8 5.8 0 0 0-4.71 2.41A5.81 5.81 0 0 0 1.6 7.66h2.22a3.6 3.6 0 0 1 7.19 0v1.6h2.22v-1.6a3.6 3.6 0 0 1 7.19 0h2.22a5.82 5.82 0 0 0-5.81-5.81z"
      />
    </svg>
  );
}

export default Icon;
