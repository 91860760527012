function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15">
      <path
        fill="currentColor"
        d="M10 10c-2 0-7 1-7 3v2h14v-2c0-2-5-3-7-3zm4-6c0 2-2 4-4 4S6 6 6 4s2-4 4-4 4 2 4 4zm1 3v1h2v2h1V8h2V7h-2V5h-1v2z"
      />
    </svg>
  );
}

export default Icon;
