function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10">
      <g fill="none" stroke="currentColor">
        <path strokeWidth="1.5" d="M1 5a8 8 0 0 0 14 0" />
        <path d="M13 4A7 7 0 0 0 3 4" />
      </g>
    </svg>
  );
}

export default Icon;
