function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        fill="currentColor"
        d="m0 2 2-2 4 4 4-4 2 2-4 4 4 4-2 2-4-4-4 4-2-2 4-4-4-4Z"
      />
    </svg>
  );
}

export default Icon;
