function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M8.79 20.39h6.59v2a.62.62 0 0 1-.62.62H9.41a.62.62 0 0 1-.62-.62zM12.09.74a8.1 8.1 0 0 0-5.77 13.88 8.34 8.34 0 0 1 2.38 4.52h6.57a8.33 8.33 0 0 1 2.37-4.52A8.09 8.09 0 0 0 12.09.74zM17 9.88h-1.28a.49.49 0 0 0-.34.84l.9.9-1.5 1.5-.9-.91a.49.49 0 0 0-.84.35v1.27h-2.12v-1.27a.49.49 0 0 0-.83-.35l-.9.91-1.5-1.5.9-.9a.49.49 0 0 0-.34-.84H7V7.76h1.25a.48.48 0 0 0 .34-.83L7.69 6l1.5-1.5.9.9a.48.48 0 0 0 .83-.34V3.81H13v1.28a.49.49 0 0 0 .84.34l.9-.9L16.28 6l-.91.9a.49.49 0 0 0 .35.83H17zm-5.02 1.07a2.13 2.13 0 1 0 0-4.26 2.13 2.13 0 0 0 0 4.26z"
      />
    </svg>
  );
}

export default Icon;
