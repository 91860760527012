function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M14.6 21.68a3.76 3.76 0 1 0 0-7.52 3.76 3.76 0 0 0 0 7.52zm8-11.68a2.74 2.74 0 0 0-.6-1.55l-2.66-3.4a4.66 4.66 0 0 0-2.75-1.7 88.46 88.46 0 0 0-15.44-1v16.09h8a4.41 4.41 0 0 1 0-.52 5.5 5.5 0 1 1 11-.26l.58-.08a2.73 2.73 0 0 0 2.17-2.82zM3.66 9.26V4.42c4.02-.06 8.03.23 12 .86a2.76 2.76 0 0 1 1.61 1l2.34 3z"
      />
    </svg>
  );
}

export default Icon;
