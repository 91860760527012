function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7 19H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h2l3 4h5c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1h-5l-3 4zm15-2V7a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2v1h2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-2v1c0 1.1.9 2 2 2h5a2 2 0 0 0 2-2zm-11-3h3v-4h-3v4z"
      />
    </svg>
  );
}

export default Icon;
