function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.15 6.84a2.89 2.89 0 1 0 0-5.78 2.89 2.89 0 0 0 0 5.78zm0 8.14a2.89 2.89 0 1 0 0-5.78 2.89 2.89 0 0 0 0 5.78zm0 8.14a2.89 2.89 0 1 0 0-5.78 2.89 2.89 0 0 0 0 5.78z"
      />
    </svg>
  );
}

export default Icon;
