function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm5.1 13.5L13.6 15l-3.5-3.5L6.6 15l-1.5-1.5L8.6 10 5.1 6.5 6.6 5l3.5 3.5L13.6 5l1.5 1.5-3.5 3.5 3.5 3.5Z"
      />
    </svg>
  );
}

export default Icon;
