function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22">
      <path
        fill="currentColor"
        d="M15 22H3a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3ZM9 11a7.99 7.99 0 0 0-6.92 4 7.99 7.99 0 0 0 13.84 0A7.99 7.99 0 0 0 9 11Zm0 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-8c-1.1 0-2.1.45-2.83 1.17L7.6 6.6a2 2 0 0 1 2.82 0l1.42-1.42A3.99 3.99 0 0 0 9 4ZM3.34 2.34l1.42 1.42a5.98 5.98 0 0 1 8.48 0l1.42-1.42a7.98 7.98 0 0 0-11.32 0Z"
      />
    </svg>
  );
}

export default Icon;
