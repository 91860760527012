function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m21.24 19.8-4.16-5.08A8.26 8.26 0 1 0 14.24 17l4.29 5.09a1.77 1.77 0 0 0 2.71-2.29zM4.77 9.56a6.7 6.7 0 0 1 11.7 0 6.71 6.71 0 0 1-11.7 0zm5.85 2.53a2.53 2.53 0 1 0 0-5.06 2.53 2.53 0 0 0 0 5.06z"
      />
    </svg>
  );
}

export default Icon;
