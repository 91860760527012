function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m14.042 15.933-4.125-.683-2.055-1.26a4.628 4.628 0 0 0-2.362-.682h-.908v5.535H8.74a1.928 1.928 0 1 1 3.855-.098 12.437 12.437 0 0 0 1.665-.195l.517-.097a.495.495 0 0 0 .398-.488v-.69a1.35 1.35 0 0 0-1.133-1.342z"
      />
      <path
        fill="currentColor"
        d="M10.713 20.005a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34zM11.958 9.25l4.125-.667 2.055-1.268a4.56 4.56 0 0 1 2.362-.682h.885v5.527H17.26a1.929 1.929 0 0 0-3.855-.09 13.817 13.817 0 0 1-1.665-.195l-.517-.097a.495.495 0 0 1-.398-.488v-.697a1.358 1.358 0 0 1 1.133-1.343z"
      />
      <path
        fill="currentColor"
        d="M15.288 13.322a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34zm-9.383-1.769h-1.29V6.625h5.58v1.29h-4.29v3.638zm15.473 7.282H16.45v-1.29h3.645v-3.638h1.283v4.928z"
      />
    </svg>
  );
}

export default Icon;
