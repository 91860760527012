function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <g fill="none" stroke="currentColor">
        <circle cx="16" cy="16" r="12" strokeWidth="3" />
        <path strokeWidth="2" d="m16 16-3-9" />
        <path strokeWidth="3" d="m16 16 6 4" />
      </g>
      <circle cx="16" cy="16" r="2" />
    </svg>
  );
}

export default Icon;
