function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.33 14.26h2.12V9.74h-2.12v-4H1.55v12.57h18.78zM5 11.94l5.06.15V7.24L17.62 12h-5.06v4.78z"
      />
    </svg>
  );
}

export default Icon;
