function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.8 6.48V4.52H2.91v1.96zm0 6.7v-1.96H2.91v1.96zm.01 6.85v-1.96H2.92v1.96zm-2.75-3.56H12.9v5.16h5.16zm-7.18-6.84H5.72v5.16h5.16zm7.18-6.7H12.9v5.16h5.16z"
      />
    </svg>
  );
}

export default Icon;
