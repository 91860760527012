function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        fill="none"
        stroke="currentColor"
        d="M6 11a3 3 0 0 0 3-3V7v1a3 3 0 0 0 3 3M9 6.5v5"
      />
      <path
        fill="currentColor"
        d="M9 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM6 5v1h6V5c-2-1-4-1-6 0Z
          M3 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 5v1h6v-1c-2-1-4-1-6 0Z
          M9 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 5v1h6v-1c-2-1-4-1-6 0Z
          M15 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3 5v1h6v-1c-2-1-4-1-6 0Z"
      />
    </svg>
  );
}

export default Icon;
