function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20.7 12.1h-1.2V8.8c0-.3-.1-.6-.2-.9-.5-1.1-1.8-1.6-2.9-1.1L4 12.1a1 1 0 0 0-1 1v8.5c0 .6.5 1 1 1h16.6c.6 0 1-.5 1-1v-8.5c.1-.5-.4-1-.9-1zm-3.5-3.4h.2c.1 0 .1.1.1.2v3.3H9l8.2-3.5zm-1.1-6.2c-.2-.5-.6-.9-1.1-1.1-.5-.2-1.1-.2-1.6.1l-7 3.2a2.82 2.82 0 1 0-3 4.6c.3.1.7.2 1 .2.4 0 .8-.1 1.2-.3 1.2-.5 1.8-1.7 1.6-2.9l4.3-2 .9 1.9 1.6-.7-.9-1.9 1-.6h.2c.1 0 .1.1.2.1l.7 1.6 1.6-.7-.7-1.5zM4.8 7.7c-.6.3-1.2 0-1.5-.5-.1-.3-.1-.6 0-.8.1-.4.3-.6.6-.7.1-.1.3-.1.5-.1.4 0 .8.3 1 .6.2.6 0 1.2-.6 1.5z"
      />
    </svg>
  );
}

export default Icon;
