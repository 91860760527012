function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M9 20H6c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v14c0 .6-.4 1-1 1zm10-1V5c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h3c.6 0 1-.4 1-1z"
      />
    </svg>
  );
}

export default Icon;
