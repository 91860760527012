function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M6 19.4V4.6C6 3 7.7 2 9 2.9l12.2 7.4a2 2 0 0 1 0 3.4L9 21.1c-1.3.9-3-.1-3-1.7z"
      />
    </svg>
  );
}

export default Icon;
