function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M17.54 6.29a7.8 7.8 0 0 0-11 0l-.22.22 1.39 1.41.22-.22a5.79 5.79 0 0 1 8.2 0l.22.22 1.41-1.41zM5.7 5.47a9 9 0 0 1 12.66 0l.22.22L20 4.28l-.22-.22a10.92 10.92 0 0 0-15.47 0l-.23.22 1.4 1.41zm2.01 10.61L6.3 17.49l.22.22a7.8 7.8 0 0 0 11 0l.22-.22-1.41-1.41-.22.22a5.79 5.79 0 0 1-8.2 0zm10.87 2.23-.22.22a9 9 0 0 1-12.66 0l-.22-.22-1.41 1.41.23.22a10.92 10.92 0 0 0 15.47 0l.22-.22zM16.28 12a4.25 4.25 0 1 0-8.5-.04 4.25 4.25 0 0 0 8.5.04z"
      />
    </svg>
  );
}

export default Icon;
