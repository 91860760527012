function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.56 17.63 13.75 3.15a1.9 1.9 0 0 0-3.23 0L1.31 17.6a1.91 1.91 0 0 0 1.61 2.93h18a1.91 1.91 0 0 0 1.64-2.9zm-6.83-6.95 1 1-1.56 1.57-1-1zm-4.61-5h1.53v5.4h-1.53zm-3.12 5 1.57 1.56-1 1L7 11.68zm10.7 8.17H5.07v-1.62h1.44a2.31 2.31 0 0 0 2.13-1.39 3.5 3.5 0 0 1 6.41 0 2.32 2.32 0 0 0 2.13 1.39h1.52z"
      />
    </svg>
  );
}

export default Icon;
