function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="m4.38 16-.38.37v6.76h24v-6.76l-.38-.37H4.38zM27 26.13v3h4v-14l-2-2h2v-2h-3l-3-5c-1.5-1.5-16.5-1.5-18 0l-3 5H1v2h2l-2 2v14h4v-3h22Zm2-2H3v-8.18L5 14l3.44-6.38C9.36 7.34 12.03 7 16 7s6.64.34 7.56.62L27 14l2 1.95v8.18ZM23.8 7.7" />
    </svg>
  );
}

export default Icon;
