function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Zm9,13H7v6H25Z"
      />
    </svg>
  );
}

export default Icon;
