function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 28">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2px"
        strokeLinecap="round"
        d="M13,21A6,6,0,0,1,1,21a6.1,6.1,0,0,1,2-4.5V5a4,4,0,0,1,8,0V16.5A6.1,6.1,0,0,1,13,21ZM7,5V21"
      />
      <circle fill="currentColor" cx="7" cy="21" r="3" />
    </svg>
  );
}

export default Icon;
