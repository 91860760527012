function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 25">
      <clipPath id="SVGID_000001565790223421338229600000147139453437675_">
        <path d="M0 24V0h12L1 24Zm3 0L14 0l4 1v24H3Z" />
      </clipPath>
      <path
        fill="currentColor"
        d="M5 25 16 1l-2-1L3 24l2 1zm13-6v-8a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3zm-2.1-4a8 8 0 0 1-13.8 0 8 8 0 0 1 13.8 0zM6 15a3 3 0 1 0 6 0 3 3 0 1 0-6 0zm5.8-9.8A3.6 3.6 0 0 0 9 4a4 4 0 0 0-2.8 1.2l1.4 1.4a2 2 0 0 1 2.8 0l1.4-1.4zM3.4 2.3l1.4 1.5a6 6 0 0 1 8.5 0l1.4-1.4a8 8 0 0 0-11.3-.1z"
        clipPath="url(#SVGID_000001565790223421338229600000147139453437675_)"
      />
    </svg>
  );
}

export default Icon;
