function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M1.48 5.64v7.1h3.57a7.1 7.1 0 1 0 14.2 0h3.57v-7.1zm10.67 11.8a4.7 4.7 0 0 1-4.7-4.7h9.4a4.69 4.69 0 0 1-4.7 4.7z"
      />
    </svg>
  );
}

export default Icon;
