function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 18">
      <g fill="none" stroke="currentColor">
        <circle cx="4" cy="4" r="3" strokeWidth="2" />
        <path strokeLinecap="round" strokeWidth="1.4" d="M4 7v10" />
      </g>
    </svg>
  );
}

export default Icon;
