function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
      <path
        fill="currentColor"
        d="M11 14V7H9v7H6l4 4 4-4h-3zM4 0 0 4h3v7h2V4h3L4 0z"
      />
    </svg>
  );
}

export default Icon;
