function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M18 15.34a8.68 8.68 0 0 0-12.27 0l-2.1-2.06a11.59 11.59 0 0 1 16.4 0zm-3.12 3.09a4.18 4.18 0 0 0-5.92 0l-2.07-2.07a7.12 7.12 0 0 1 10.06 0zM21 12.3a13 13 0 0 0-18.36 0L.59 10.23a15.9 15.9 0 0 1 22.49 0z"
      />
    </svg>
  );
}

export default Icon;
