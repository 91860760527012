function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M14.5 2.3h-2v2.8H11v5h1.5v9.7h2v-9.7H16v-5h-1.5zm8.1 9.8H21V2.3h-1.9v9.8h-1.6v5.1h1.6v2.6H21v-2.6h1.6zM7.2 10.9V3.8c0-.8-.7-1.5-1.5-1.5-.7 0-1.4.7-1.4 1.5v7.1a4.9 4.9 0 0 0 1.4 9.6c2.7 0 4.9-2.2 4.9-4.9.1-2.2-1.4-4.1-3.4-4.7zm-1.4 6.9c-1.3 0-2.3-1-2.3-2.2s1-2.3 2.2-2.3 2.3 1 2.3 2.2c0 1.3-1 2.3-2.2 2.3z"
      />
    </svg>
  );
}

export default Icon;
