// THIS IS AN AUTOGENERATED FILE, DO NOT MODIFY DIRECTLY
/* istanbul ignore file */

import I_20Past1140 from 'src/components/common/Icon/Icons/20Past1140';
import I_Add from 'src/components/common/Icon/Icons/Add';
import I_ArrowApproachingSide from 'src/components/common/Icon/Icons/ArrowApproachingSide';
import I_ArrowAvoidingCircle from 'src/components/common/Icon/Icons/ArrowAvoidingCircle';
import I_ArrowCircleClockwise from 'src/components/common/Icon/Icons/ArrowCircleClockwise';
import I_ArrowDottedS from 'src/components/common/Icon/Icons/ArrowDottedS';
import I_ArrowLeavingSide from 'src/components/common/Icon/Icons/ArrowLeavingSide';
import I_ArrowNEWithArrowDottedN from 'src/components/common/Icon/Icons/ArrowNEWithArrowDottedN';
import I_ArrowsCardinalWithPlus from 'src/components/common/Icon/Icons/ArrowsCardinalWithPlus';
import I_ArrowsOrdinal from 'src/components/common/Icon/Icons/ArrowsOrdinal';
import I_ArrowsOrdinalWithCirlce from 'src/components/common/Icon/Icons/ArrowsOrdinalWithCirlce';
import I_ArrowsSwapHorizontal from 'src/components/common/Icon/Icons/ArrowsSwapHorizontal';
import I_ArrowsSwapVertical from 'src/components/common/Icon/Icons/ArrowsSwapVertical';
import I_ArrowTurningLeft from 'src/components/common/Icon/Icons/ArrowTurningLeft';
import I_ArrowUpFromLine from 'src/components/common/Icon/Icons/ArrowUpFromLine';
import I_ArrowUpToLine from 'src/components/common/Icon/Icons/ArrowUpToLine';
import I_BatteryAtHalf from 'src/components/common/Icon/Icons/BatteryAtHalf';
import I_BatteryEmpty from 'src/components/common/Icon/Icons/BatteryEmpty';
import I_BatteryFull from 'src/components/common/Icon/Icons/BatteryFull';
import I_BatteryWithLightning from 'src/components/common/Icon/Icons/BatteryWithLightning';
import I_Bell from 'src/components/common/Icon/Icons/Bell';
import I_BellWithCog from 'src/components/common/Icon/Icons/BellWithCog';
import I_Belongings from 'src/components/common/Icon/Icons/Belongings';
import I_BiasSquareWithExclaimation from 'src/components/common/Icon/Icons/BiasSquareWithExclaimation';
import I_BrowserWithMarkup from 'src/components/common/Icon/Icons/BrowserWithMarkup';
import I_BrowserWithMarkupStack from 'src/components/common/Icon/Icons/BrowserWithMarkupStack';
import I_BuckleLatched from 'src/components/common/Icon/Icons/BuckleLatched';
import I_BuckleUnlatched from 'src/components/common/Icon/Icons/BuckleUnlatched';
import I_BundleWithArrowUp from 'src/components/common/Icon/Icons/BundleWithArrowUp';
import I_Bungalow from 'src/components/common/Icon/Icons/Bungalow';
import I_BungalowWithArrowUp from 'src/components/common/Icon/Icons/BungalowWithArrowUp';
import I_Calendar from 'src/components/common/Icon/Icons/Calendar';
import I_CameraWithPlay from 'src/components/common/Icon/Icons/CameraWithPlay';
import I_CarBootClosed from 'src/components/common/Icon/Icons/CarBootClosed';
import I_CarBootOpen from 'src/components/common/Icon/Icons/CarBootOpen';
import I_CarByMultiply from 'src/components/common/Icon/Icons/CarByMultiply';
import I_CarByTick from 'src/components/common/Icon/Icons/CarByTick';
import I_CarFacingCar from 'src/components/common/Icon/Icons/CarFacingCar';
import I_CarFront from 'src/components/common/Icon/Icons/CarFront';
import I_CarFrontBesideLetterI from 'src/components/common/Icon/Icons/CarFrontBesideLetterI';
import I_CarFrontBesidePerson from 'src/components/common/Icon/Icons/CarFrontBesidePerson';
import I_CarFrontOpen from 'src/components/common/Icon/Icons/CarFrontOpen';
import I_CarFrontOpenWithPerson from 'src/components/common/Icon/Icons/CarFrontOpenWithPerson';
import I_CarFrontWithCircleWithPlus from 'src/components/common/Icon/Icons/CarFrontWithCircleWithPlus';
import I_CarFrontWithMultiply from 'src/components/common/Icon/Icons/CarFrontWithMultiply';
import I_CarFrontWithQuestion from 'src/components/common/Icon/Icons/CarFrontWithQuestion';
import I_CarFrontWithStrikethrough from 'src/components/common/Icon/Icons/CarFrontWithStrikethrough';
import I_CarMovingForward from 'src/components/common/Icon/Icons/CarMovingForward';
import I_CarRear from 'src/components/common/Icon/Icons/CarRear';
import I_CarSideBesidePin from 'src/components/common/Icon/Icons/CarSideBesidePin';
import I_CarStack from 'src/components/common/Icon/Icons/CarStack';
import I_CarTopN from 'src/components/common/Icon/Icons/CarTopN';
import I_CarTopS from 'src/components/common/Icon/Icons/CarTopS';
import I_CarTopSE from 'src/components/common/Icon/Icons/CarTopSE';
import I_CarTopSOpen from 'src/components/common/Icon/Icons/CarTopSOpen';
import I_CarWindowClosed from 'src/components/common/Icon/Icons/CarWindowClosed';
import I_CarWindowOpen from 'src/components/common/Icon/Icons/CarWindowOpen';
import I_CeilingLightOff from 'src/components/common/Icon/Icons/CeilingLightOff';
import I_CeilingLightOn from 'src/components/common/Icon/Icons/CeilingLightOn';
import I_ChevronDown from 'src/components/common/Icon/Icons/ChevronDown';
import I_ChevronLeft from 'src/components/common/Icon/Icons/ChevronLeft';
import I_ChevronRight from 'src/components/common/Icon/Icons/ChevronRight';
import I_ChevronRightBesideChevronRight from 'src/components/common/Icon/Icons/ChevronRightBesideChevronRight';
import I_ChevronRightBesidePin from 'src/components/common/Icon/Icons/ChevronRightBesidePin';
import I_ChevronUp from 'src/components/common/Icon/Icons/ChevronUp';
import I_Circle from 'src/components/common/Icon/Icons/Circle';
import I_CircleBeside2Signals from 'src/components/common/Icon/Icons/CircleBeside2Signals';
import I_CircleDashedWithCircle from 'src/components/common/Icon/Icons/CircleDashedWithCircle';
import I_CircleDashedWithSpeechBubble from 'src/components/common/Icon/Icons/CircleDashedWithSpeechBubble';
import I_CircleOutline from 'src/components/common/Icon/Icons/CircleOutline';
import I_CircleOutlineWithLetterI from 'src/components/common/Icon/Icons/CircleOutlineWithLetterI';
import I_CircleWithCar from 'src/components/common/Icon/Icons/CircleWithCar';
import I_CircleWithExclamation from 'src/components/common/Icon/Icons/CircleWithExclamation';
import I_CircleWithLetterI from 'src/components/common/Icon/Icons/CircleWithLetterI';
import I_CircleWithMinus from 'src/components/common/Icon/Icons/CircleWithMinus';
import I_CircleWithMultiply from 'src/components/common/Icon/Icons/CircleWithMultiply';
import I_CircleWithQuestionMark from 'src/components/common/Icon/Icons/CircleWithQuestionMark';
import I_CircleWithTick from 'src/components/common/Icon/Icons/CircleWithTick';
import I_CircleWithTickAndCircleWithExclamationStack from 'src/components/common/Icon/Icons/CircleWithTickAndCircleWithExclamationStack';
import I_CircleWithTimer from 'src/components/common/Icon/Icons/CircleWithTimer';
import I_CirlceWithHeadAndShoulders from 'src/components/common/Icon/Icons/CirlceWithHeadAndShoulders';
import I_Cog from 'src/components/common/Icon/Icons/Cog';
import I_Columns3 from 'src/components/common/Icon/Icons/Columns3';
import I_Crosshairs from 'src/components/common/Icon/Icons/Crosshairs';
import I_Crosshairs_2 from 'src/components/common/Icon/Icons/Crosshairs_2';
import I_Diamond from 'src/components/common/Icon/Icons/Diamond';
import I_DiamondWithExclamation from 'src/components/common/Icon/Icons/DiamondWithExclamation';
import I_DocumentWithTick from 'src/components/common/Icon/Icons/DocumentWithTick';
import I_DoorOpenWithArrowIn from 'src/components/common/Icon/Icons/DoorOpenWithArrowIn';
import I_Dots1x3 from 'src/components/common/Icon/Icons/Dots1x3';
import I_Dots1x3_2 from 'src/components/common/Icon/Icons/Dots1x3_2';
import I_Dots2x3 from 'src/components/common/Icon/Icons/Dots2x3';
import I_Dots3x1 from 'src/components/common/Icon/Icons/Dots3x1';
import I_EnvelopeWithAtSign from 'src/components/common/Icon/Icons/EnvelopeWithAtSign';
import I_EyeClosed from 'src/components/common/Icon/Icons/EyeClosed';
import I_EyeOpen from 'src/components/common/Icon/Icons/EyeOpen';
import I_FlatTyre from 'src/components/common/Icon/Icons/FlatTyre';
import I_Funnel from 'src/components/common/Icon/Icons/Funnel';
import I_FunnelLines from 'src/components/common/Icon/Icons/FunnelLines';
import I_HamburgerMenu from 'src/components/common/Icon/Icons/HamburgerMenu';
import I_HeadAndShoulders from 'src/components/common/Icon/Icons/HeadAndShoulders';
import I_HeadAndShouldersBeside3Squares from 'src/components/common/Icon/Icons/HeadAndShouldersBeside3Squares';
import I_HeadAndShouldersBesidePlus from 'src/components/common/Icon/Icons/HeadAndShouldersBesidePlus';
import I_HeadAndShouldersBesidePlus_2 from 'src/components/common/Icon/Icons/HeadAndShouldersBesidePlus_2';
import I_HeadAndShouldersGroupBesideConnectingLines from 'src/components/common/Icon/Icons/HeadAndShouldersGroupBesideConnectingLines';
import I_HeadAndShouldersWithSeatbelt from 'src/components/common/Icon/Icons/HeadAndShouldersWithSeatbelt';
import I_HeadAndShouldersWithStrikethrough from 'src/components/common/Icon/Icons/HeadAndShouldersWithStrikethrough';
import I_HeadWithHeadset from 'src/components/common/Icon/Icons/HeadWithHeadset';
import I_HouseClosed from 'src/components/common/Icon/Icons/HouseClosed';
import I_HouseOpen from 'src/components/common/Icon/Icons/HouseOpen';
import I_HouseWithArrowUp from 'src/components/common/Icon/Icons/HouseWithArrowUp';
import I_HouseWithCar from 'src/components/common/Icon/Icons/HouseWithCar';
import I_JiraLogo from 'src/components/common/Icon/Icons/JiraLogo';
import I_LetterI from 'src/components/common/Icon/Icons/LetterI';
import I_LightBulbWithCog from 'src/components/common/Icon/Icons/LightBulbWithCog';
import I_MagnifyingGlass from 'src/components/common/Icon/Icons/MagnifyingGlass';
import I_MagnifyingGlassWithEye from 'src/components/common/Icon/Icons/MagnifyingGlassWithEye';
import I_Map from 'src/components/common/Icon/Icons/Map';
import I_MapWithArrowUp from 'src/components/common/Icon/Icons/MapWithArrowUp';
import I_Minus from 'src/components/common/Icon/Icons/Minus';
import I_MoonHalf from 'src/components/common/Icon/Icons/MoonHalf';
import I_Multiply from 'src/components/common/Icon/Icons/Multiply';
import I_MultiplyFat from 'src/components/common/Icon/Icons/MultiplyFat';
import I_NodesTopWithMultiply from 'src/components/common/Icon/Icons/NodesTopWithMultiply';
import I_NoEntry from 'src/components/common/Icon/Icons/NoEntry';
import I_OctagonWithHandOpen from 'src/components/common/Icon/Icons/OctagonWithHandOpen';
import I_OctagonWithPauseButton from 'src/components/common/Icon/Icons/OctagonWithPauseButton';
import I_PadlockClosed from 'src/components/common/Icon/Icons/PadlockClosed';
import I_PadlockOpen from 'src/components/common/Icon/Icons/PadlockOpen';
import I_Pause from 'src/components/common/Icon/Icons/Pause';
import I_Pencil from 'src/components/common/Icon/Icons/Pencil';
import I_PersonRunning from 'src/components/common/Icon/Icons/PersonRunning';
import I_PetrolPump from 'src/components/common/Icon/Icons/PetrolPump';
import I_PetrolPumpFull from 'src/components/common/Icon/Icons/PetrolPumpFull';
import I_PhoneDown from 'src/components/common/Icon/Icons/PhoneDown';
import I_PhoneWithSignal from 'src/components/common/Icon/Icons/PhoneWithSignal';
import I_Pin from 'src/components/common/Icon/Icons/Pin';
import I_PinHead from 'src/components/common/Icon/Icons/PinHead';
import I_PinWithArrowRight from 'src/components/common/Icon/Icons/PinWithArrowRight';
import I_PinWithTick from 'src/components/common/Icon/Icons/PinWithTick';
import I_Play from 'src/components/common/Icon/Icons/Play';
import I_Power from 'src/components/common/Icon/Icons/Power';
import I_RoadPerspectiveWithPin from 'src/components/common/Icon/Icons/RoadPerspectiveWithPin';
import I_RouteWithCircleEnds from 'src/components/common/Icon/Icons/RouteWithCircleEnds';
import I_RouteWithPinEnds from 'src/components/common/Icon/Icons/RouteWithPinEnds';
import I_RubbishBin from 'src/components/common/Icon/Icons/RubbishBin';
import I_ShieldWithTick from 'src/components/common/Icon/Icons/ShieldWithTick';
import I_ShieldWithTick_2 from 'src/components/common/Icon/Icons/ShieldWithTick_2';
import I_Signal from 'src/components/common/Icon/Icons/Signal';
import I_Signal_2 from 'src/components/common/Icon/Icons/Signal_2';
import I_SignalBesideSquareWithHeadAndShoulders from 'src/components/common/Icon/Icons/SignalBesideSquareWithHeadAndShoulders';
import I_SignalWithStrikethrough from 'src/components/common/Icon/Icons/SignalWithStrikethrough';
import I_SignalWithStrikethrough_2 from 'src/components/common/Icon/Icons/SignalWithStrikethrough_2';
import I_SlidersHorizontal from 'src/components/common/Icon/Icons/SlidersHorizontal';
import I_SlidersVertical from 'src/components/common/Icon/Icons/SlidersVertical';
import I_SpannerStack from 'src/components/common/Icon/Icons/SpannerStack';
import I_SpeechBubbleWithDots3x1 from 'src/components/common/Icon/Icons/SpeechBubbleWithDots3x1';
import I_SpeechBubbleWithHeart from 'src/components/common/Icon/Icons/SpeechBubbleWithHeart';
import I_SpeechBubbleWithLetterI from 'src/components/common/Icon/Icons/SpeechBubbleWithLetterI';
import I_SquareDashedWithDottedCorners from 'src/components/common/Icon/Icons/SquareDashedWithDottedCorners';
import I_SquareDashedWithSquare from 'src/components/common/Icon/Icons/SquareDashedWithSquare';
import I_Squares2x2SolidSE from 'src/components/common/Icon/Icons/Squares2x2SolidSE';
import I_SquareStack from 'src/components/common/Icon/Icons/SquareStack';
import I_SquareWithArrowNEZigZag from 'src/components/common/Icon/Icons/SquareWithArrowNEZigZag';
import I_SquareWithEyeBesideSignal from 'src/components/common/Icon/Icons/SquareWithEyeBesideSignal';
import I_SquareWithEyeBesideSignalStrikethrough from 'src/components/common/Icon/Icons/SquareWithEyeBesideSignalStrikethrough';
import I_SquareWithHorizontalCars from 'src/components/common/Icon/Icons/SquareWithHorizontalCars';
import I_SquareWithPencil from 'src/components/common/Icon/Icons/SquareWithPencil';
import I_SquareWithPlusStack from 'src/components/common/Icon/Icons/SquareWithPlusStack';
import I_SquareWithStripesDiagonal from 'src/components/common/Icon/Icons/SquareWithStripesDiagonal';
import I_SquareWithTickStack from 'src/components/common/Icon/Icons/SquareWithTickStack';
import I_SS_DEPLOYMENT_FAILED from 'src/components/common/Icon/Icons/SS_DEPLOYMENT_FAILED';
import I_SS_DEPLOYMENT_IN_PROGRESS from 'src/components/common/Icon/Icons/SS_DEPLOYMENT_IN_PROGRESS';
import I_SS_DEPLOYMENT_MISSING from 'src/components/common/Icon/Icons/SS_DEPLOYMENT_MISSING';
import I_SS_DEPLOYMENT_PENDING from 'src/components/common/Icon/Icons/SS_DEPLOYMENT_PENDING';
import I_SS_OFFLINE from 'src/components/common/Icon/Icons/SS_OFFLINE';
import I_SS_ON_DISPATCH from 'src/components/common/Icon/Icons/SS_ON_DISPATCH';
import I_SS_ON_MISSION from 'src/components/common/Icon/Icons/SS_ON_MISSION';
import I_SS_ON_RIDE from 'src/components/common/Icon/Icons/SS_ON_RIDE';
import I_SS_ONLINE from 'src/components/common/Icon/Icons/SS_ONLINE';
import I_SS_PARTIALLY_ONLINE from 'src/components/common/Icon/Icons/SS_PARTIALLY_ONLINE';
import I_SS_REGISTRATION_REVOKED from 'src/components/common/Icon/Icons/SS_REGISTRATION_REVOKED';
import I_StarburstWithExlamation from 'src/components/common/Icon/Icons/StarburstWithExlamation';
import I_Tack from 'src/components/common/Icon/Icons/Tack';
import I_TagLeftWithMultiply from 'src/components/common/Icon/Icons/TagLeftWithMultiply';
import I_TagLeftWithNumberSign from 'src/components/common/Icon/Icons/TagLeftWithNumberSign';
import I_Thermometer from 'src/components/common/Icon/Icons/Thermometer';
import I_Tick from 'src/components/common/Icon/Icons/Tick';
import I_TicketWithTick from 'src/components/common/Icon/Icons/TicketWithTick';
import I_TrafficLight from 'src/components/common/Icon/Icons/TrafficLight';
import I_Triangle from 'src/components/common/Icon/Icons/Triangle';
import I_TriangleDown from 'src/components/common/Icon/Icons/TriangleDown';
import I_TriangleUp from 'src/components/common/Icon/Icons/TriangleUp';
import I_TriangleUpAboveTriangleDown from 'src/components/common/Icon/Icons/TriangleUpAboveTriangleDown';
import I_TriangleWithExclamation from 'src/components/common/Icon/Icons/TriangleWithExclamation';
import I_TriangleWithSpeedBump from 'src/components/common/Icon/Icons/TriangleWithSpeedBump';
import I_TurningCogs from 'src/components/common/Icon/Icons/TurningCogs';
import I_VortexWithCar from 'src/components/common/Icon/Icons/VortexWithCar';
import I_WindShieldWithWiperFluid from 'src/components/common/Icon/Icons/WindShieldWithWiperFluid';
import { objectKeys } from 'src/tools/object/objectKeys';

export type IconName =
  | '20Past1140'
  | 'Add'
  | 'ArrowApproachingSide'
  | 'ArrowAvoidingCircle'
  | 'ArrowCircleClockwise'
  | 'ArrowDottedS'
  | 'ArrowLeavingSide'
  | 'ArrowNEWithArrowDottedN'
  | 'ArrowsCardinalWithPlus'
  | 'ArrowsOrdinal'
  | 'ArrowsOrdinalWithCirlce'
  | 'ArrowsSwapHorizontal'
  | 'ArrowsSwapVertical'
  | 'ArrowTurningLeft'
  | 'ArrowUpFromLine'
  | 'ArrowUpToLine'
  | 'BatteryAtHalf'
  | 'BatteryEmpty'
  | 'BatteryFull'
  | 'BatteryWithLightning'
  | 'Bell'
  | 'BellWithCog'
  | 'Belongings'
  | 'BiasSquareWithExclaimation'
  | 'BrowserWithMarkup'
  | 'BrowserWithMarkupStack'
  | 'BuckleLatched'
  | 'BuckleUnlatched'
  | 'BundleWithArrowUp'
  | 'Bungalow'
  | 'BungalowWithArrowUp'
  | 'Calendar'
  | 'CameraWithPlay'
  | 'CarBootClosed'
  | 'CarBootOpen'
  | 'CarByMultiply'
  | 'CarByTick'
  | 'CarFacingCar'
  | 'CarFront'
  | 'CarFrontBesideLetterI'
  | 'CarFrontBesidePerson'
  | 'CarFrontOpen'
  | 'CarFrontOpenWithPerson'
  | 'CarFrontWithCircleWithPlus'
  | 'CarFrontWithMultiply'
  | 'CarFrontWithQuestion'
  | 'CarFrontWithStrikethrough'
  | 'CarMovingForward'
  | 'CarRear'
  | 'CarSideBesidePin'
  | 'CarStack'
  | 'CarTopN'
  | 'CarTopS'
  | 'CarTopSE'
  | 'CarTopSOpen'
  | 'CarWindowClosed'
  | 'CarWindowOpen'
  | 'CeilingLightOff'
  | 'CeilingLightOn'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronRightBesideChevronRight'
  | 'ChevronRightBesidePin'
  | 'ChevronUp'
  | 'Circle'
  | 'CircleBeside2Signals'
  | 'CircleDashedWithCircle'
  | 'CircleDashedWithSpeechBubble'
  | 'CircleOutline'
  | 'CircleOutlineWithLetterI'
  | 'CircleWithCar'
  | 'CircleWithExclamation'
  | 'CircleWithLetterI'
  | 'CircleWithMinus'
  | 'CircleWithMultiply'
  | 'CircleWithQuestionMark'
  | 'CircleWithTick'
  | 'CircleWithTickAndCircleWithExclamationStack'
  | 'CircleWithTimer'
  | 'CirlceWithHeadAndShoulders'
  | 'Cog'
  | 'Columns3'
  | 'Crosshairs'
  | 'Crosshairs_2'
  | 'Diamond'
  | 'DiamondWithExclamation'
  | 'DocumentWithTick'
  | 'DoorOpenWithArrowIn'
  | 'Dots1x3'
  | 'Dots1x3_2'
  | 'Dots2x3'
  | 'Dots3x1'
  | 'EnvelopeWithAtSign'
  | 'EyeClosed'
  | 'EyeOpen'
  | 'FlatTyre'
  | 'Funnel'
  | 'FunnelLines'
  | 'HamburgerMenu'
  | 'HeadAndShoulders'
  | 'HeadAndShouldersBeside3Squares'
  | 'HeadAndShouldersBesidePlus'
  | 'HeadAndShouldersBesidePlus_2'
  | 'HeadAndShouldersGroupBesideConnectingLines'
  | 'HeadAndShouldersWithSeatbelt'
  | 'HeadAndShouldersWithStrikethrough'
  | 'HeadWithHeadset'
  | 'HouseClosed'
  | 'HouseOpen'
  | 'HouseWithArrowUp'
  | 'HouseWithCar'
  | 'JiraLogo'
  | 'LetterI'
  | 'LightBulbWithCog'
  | 'MagnifyingGlass'
  | 'MagnifyingGlassWithEye'
  | 'Map'
  | 'MapWithArrowUp'
  | 'Minus'
  | 'MoonHalf'
  | 'Multiply'
  | 'MultiplyFat'
  | 'NodesTopWithMultiply'
  | 'NoEntry'
  | 'OctagonWithHandOpen'
  | 'OctagonWithPauseButton'
  | 'PadlockClosed'
  | 'PadlockOpen'
  | 'Pause'
  | 'Pencil'
  | 'PersonRunning'
  | 'PetrolPump'
  | 'PetrolPumpFull'
  | 'PhoneDown'
  | 'PhoneWithSignal'
  | 'Pin'
  | 'PinHead'
  | 'PinWithArrowRight'
  | 'PinWithTick'
  | 'Play'
  | 'Power'
  | 'RoadPerspectiveWithPin'
  | 'RouteWithCircleEnds'
  | 'RouteWithPinEnds'
  | 'RubbishBin'
  | 'ShieldWithTick'
  | 'ShieldWithTick_2'
  | 'Signal'
  | 'Signal_2'
  | 'SignalBesideSquareWithHeadAndShoulders'
  | 'SignalWithStrikethrough'
  | 'SignalWithStrikethrough_2'
  | 'SlidersHorizontal'
  | 'SlidersVertical'
  | 'SpannerStack'
  | 'SpeechBubbleWithDots3x1'
  | 'SpeechBubbleWithHeart'
  | 'SpeechBubbleWithLetterI'
  | 'SquareDashedWithDottedCorners'
  | 'SquareDashedWithSquare'
  | 'Squares2x2SolidSE'
  | 'SquareStack'
  | 'SquareWithArrowNEZigZag'
  | 'SquareWithEyeBesideSignal'
  | 'SquareWithEyeBesideSignalStrikethrough'
  | 'SquareWithHorizontalCars'
  | 'SquareWithPencil'
  | 'SquareWithPlusStack'
  | 'SquareWithStripesDiagonal'
  | 'SquareWithTickStack'
  | 'SS_DEPLOYMENT_FAILED'
  | 'SS_DEPLOYMENT_IN_PROGRESS'
  | 'SS_DEPLOYMENT_MISSING'
  | 'SS_DEPLOYMENT_PENDING'
  | 'SS_OFFLINE'
  | 'SS_ON_DISPATCH'
  | 'SS_ON_MISSION'
  | 'SS_ON_RIDE'
  | 'SS_ONLINE'
  | 'SS_PARTIALLY_ONLINE'
  | 'SS_REGISTRATION_REVOKED'
  | 'StarburstWithExlamation'
  | 'Tack'
  | 'TagLeftWithMultiply'
  | 'TagLeftWithNumberSign'
  | 'Thermometer'
  | 'Tick'
  | 'TicketWithTick'
  | 'TrafficLight'
  | 'Triangle'
  | 'TriangleDown'
  | 'TriangleUp'
  | 'TriangleUpAboveTriangleDown'
  | 'TriangleWithExclamation'
  | 'TriangleWithSpeedBump'
  | 'TurningCogs'
  | 'VortexWithCar'
  | 'WindShieldWithWiperFluid';

// I can’t work out how to deal with prettier and this comiler competing with quotes
// prettier-ignore
export const ICONS: Readonly<{ [Name in IconName]: () => JSX.Element }> = {
  '20Past1140': I_20Past1140,
  'Add': I_Add,
  'ArrowApproachingSide': I_ArrowApproachingSide,
  'ArrowAvoidingCircle': I_ArrowAvoidingCircle,
  'ArrowCircleClockwise': I_ArrowCircleClockwise,
  'ArrowDottedS': I_ArrowDottedS,
  'ArrowLeavingSide': I_ArrowLeavingSide,
  'ArrowNEWithArrowDottedN': I_ArrowNEWithArrowDottedN,
  'ArrowsCardinalWithPlus': I_ArrowsCardinalWithPlus,
  'ArrowsOrdinal': I_ArrowsOrdinal,
  'ArrowsOrdinalWithCirlce': I_ArrowsOrdinalWithCirlce,
  'ArrowsSwapHorizontal': I_ArrowsSwapHorizontal,
  'ArrowsSwapVertical': I_ArrowsSwapVertical,
  'ArrowTurningLeft': I_ArrowTurningLeft,
  'ArrowUpFromLine': I_ArrowUpFromLine,
  'ArrowUpToLine': I_ArrowUpToLine,
  'BatteryAtHalf': I_BatteryAtHalf,
  'BatteryEmpty': I_BatteryEmpty,
  'BatteryFull': I_BatteryFull,
  'BatteryWithLightning': I_BatteryWithLightning,
  'Bell': I_Bell,
  'BellWithCog': I_BellWithCog,
  'Belongings': I_Belongings,
  'BiasSquareWithExclaimation': I_BiasSquareWithExclaimation,
  'BrowserWithMarkup': I_BrowserWithMarkup,
  'BrowserWithMarkupStack': I_BrowserWithMarkupStack,
  'BuckleLatched': I_BuckleLatched,
  'BuckleUnlatched': I_BuckleUnlatched,
  'BundleWithArrowUp': I_BundleWithArrowUp,
  'Bungalow': I_Bungalow,
  'BungalowWithArrowUp': I_BungalowWithArrowUp,
  'Calendar': I_Calendar,
  'CameraWithPlay': I_CameraWithPlay,
  'CarBootClosed': I_CarBootClosed,
  'CarBootOpen': I_CarBootOpen,
  'CarByMultiply': I_CarByMultiply,
  'CarByTick': I_CarByTick,
  'CarFacingCar': I_CarFacingCar,
  'CarFront': I_CarFront,
  'CarFrontBesideLetterI': I_CarFrontBesideLetterI,
  'CarFrontBesidePerson': I_CarFrontBesidePerson,
  'CarFrontOpen': I_CarFrontOpen,
  'CarFrontOpenWithPerson': I_CarFrontOpenWithPerson,
  'CarFrontWithCircleWithPlus': I_CarFrontWithCircleWithPlus,
  'CarFrontWithMultiply': I_CarFrontWithMultiply,
  'CarFrontWithQuestion': I_CarFrontWithQuestion,
  'CarFrontWithStrikethrough': I_CarFrontWithStrikethrough,
  'CarMovingForward': I_CarMovingForward,
  'CarRear': I_CarRear,
  'CarSideBesidePin': I_CarSideBesidePin,
  'CarStack': I_CarStack,
  'CarTopN': I_CarTopN,
  'CarTopS': I_CarTopS,
  'CarTopSE': I_CarTopSE,
  'CarTopSOpen': I_CarTopSOpen,
  'CarWindowClosed': I_CarWindowClosed,
  'CarWindowOpen': I_CarWindowOpen,
  'CeilingLightOff': I_CeilingLightOff,
  'CeilingLightOn': I_CeilingLightOn,
  'ChevronDown': I_ChevronDown,
  'ChevronLeft': I_ChevronLeft,
  'ChevronRight': I_ChevronRight,
  'ChevronRightBesideChevronRight': I_ChevronRightBesideChevronRight,
  'ChevronRightBesidePin': I_ChevronRightBesidePin,
  'ChevronUp': I_ChevronUp,
  'Circle': I_Circle,
  'CircleBeside2Signals': I_CircleBeside2Signals,
  'CircleDashedWithCircle': I_CircleDashedWithCircle,
  'CircleDashedWithSpeechBubble': I_CircleDashedWithSpeechBubble,
  'CircleOutline': I_CircleOutline,
  'CircleOutlineWithLetterI': I_CircleOutlineWithLetterI,
  'CircleWithCar': I_CircleWithCar,
  'CircleWithExclamation': I_CircleWithExclamation,
  'CircleWithLetterI': I_CircleWithLetterI,
  'CircleWithMinus': I_CircleWithMinus,
  'CircleWithMultiply': I_CircleWithMultiply,
  'CircleWithQuestionMark': I_CircleWithQuestionMark,
  'CircleWithTick': I_CircleWithTick,
  'CircleWithTickAndCircleWithExclamationStack': I_CircleWithTickAndCircleWithExclamationStack,
  'CircleWithTimer': I_CircleWithTimer,
  'CirlceWithHeadAndShoulders': I_CirlceWithHeadAndShoulders,
  'Cog': I_Cog,
  'Columns3': I_Columns3,
  'Crosshairs': I_Crosshairs,
  'Crosshairs_2': I_Crosshairs_2,
  'Diamond': I_Diamond,
  'DiamondWithExclamation': I_DiamondWithExclamation,
  'DocumentWithTick': I_DocumentWithTick,
  'DoorOpenWithArrowIn': I_DoorOpenWithArrowIn,
  'Dots1x3': I_Dots1x3,
  'Dots1x3_2': I_Dots1x3_2,
  'Dots2x3': I_Dots2x3,
  'Dots3x1': I_Dots3x1,
  'EnvelopeWithAtSign': I_EnvelopeWithAtSign,
  'EyeClosed': I_EyeClosed,
  'EyeOpen': I_EyeOpen,
  'FlatTyre': I_FlatTyre,
  'Funnel': I_Funnel,
  'FunnelLines': I_FunnelLines,
  'HamburgerMenu': I_HamburgerMenu,
  'HeadAndShoulders': I_HeadAndShoulders,
  'HeadAndShouldersBeside3Squares': I_HeadAndShouldersBeside3Squares,
  'HeadAndShouldersBesidePlus': I_HeadAndShouldersBesidePlus,
  'HeadAndShouldersBesidePlus_2': I_HeadAndShouldersBesidePlus_2,
  'HeadAndShouldersGroupBesideConnectingLines': I_HeadAndShouldersGroupBesideConnectingLines,
  'HeadAndShouldersWithSeatbelt': I_HeadAndShouldersWithSeatbelt,
  'HeadAndShouldersWithStrikethrough': I_HeadAndShouldersWithStrikethrough,
  'HeadWithHeadset': I_HeadWithHeadset,
  'HouseClosed': I_HouseClosed,
  'HouseOpen': I_HouseOpen,
  'HouseWithArrowUp': I_HouseWithArrowUp,
  'HouseWithCar': I_HouseWithCar,
  'JiraLogo': I_JiraLogo,
  'LetterI': I_LetterI,
  'LightBulbWithCog': I_LightBulbWithCog,
  'MagnifyingGlass': I_MagnifyingGlass,
  'MagnifyingGlassWithEye': I_MagnifyingGlassWithEye,
  'Map': I_Map,
  'MapWithArrowUp': I_MapWithArrowUp,
  'Minus': I_Minus,
  'MoonHalf': I_MoonHalf,
  'Multiply': I_Multiply,
  'MultiplyFat': I_MultiplyFat,
  'NodesTopWithMultiply': I_NodesTopWithMultiply,
  'NoEntry': I_NoEntry,
  'OctagonWithHandOpen': I_OctagonWithHandOpen,
  'OctagonWithPauseButton': I_OctagonWithPauseButton,
  'PadlockClosed': I_PadlockClosed,
  'PadlockOpen': I_PadlockOpen,
  'Pause': I_Pause,
  'Pencil': I_Pencil,
  'PersonRunning': I_PersonRunning,
  'PetrolPump': I_PetrolPump,
  'PetrolPumpFull': I_PetrolPumpFull,
  'PhoneDown': I_PhoneDown,
  'PhoneWithSignal': I_PhoneWithSignal,
  'Pin': I_Pin,
  'PinHead': I_PinHead,
  'PinWithArrowRight': I_PinWithArrowRight,
  'PinWithTick': I_PinWithTick,
  'Play': I_Play,
  'Power': I_Power,
  'RoadPerspectiveWithPin': I_RoadPerspectiveWithPin,
  'RouteWithCircleEnds': I_RouteWithCircleEnds,
  'RouteWithPinEnds': I_RouteWithPinEnds,
  'RubbishBin': I_RubbishBin,
  'ShieldWithTick': I_ShieldWithTick,
  'ShieldWithTick_2': I_ShieldWithTick_2,
  'Signal': I_Signal,
  'Signal_2': I_Signal_2,
  'SignalBesideSquareWithHeadAndShoulders': I_SignalBesideSquareWithHeadAndShoulders,
  'SignalWithStrikethrough': I_SignalWithStrikethrough,
  'SignalWithStrikethrough_2': I_SignalWithStrikethrough_2,
  'SlidersHorizontal': I_SlidersHorizontal,
  'SlidersVertical': I_SlidersVertical,
  'SpannerStack': I_SpannerStack,
  'SpeechBubbleWithDots3x1': I_SpeechBubbleWithDots3x1,
  'SpeechBubbleWithHeart': I_SpeechBubbleWithHeart,
  'SpeechBubbleWithLetterI': I_SpeechBubbleWithLetterI,
  'SquareDashedWithDottedCorners': I_SquareDashedWithDottedCorners,
  'SquareDashedWithSquare': I_SquareDashedWithSquare,
  'Squares2x2SolidSE': I_Squares2x2SolidSE,
  'SquareStack': I_SquareStack,
  'SquareWithArrowNEZigZag': I_SquareWithArrowNEZigZag,
  'SquareWithEyeBesideSignal': I_SquareWithEyeBesideSignal,
  'SquareWithEyeBesideSignalStrikethrough': I_SquareWithEyeBesideSignalStrikethrough,
  'SquareWithHorizontalCars': I_SquareWithHorizontalCars,
  'SquareWithPencil': I_SquareWithPencil,
  'SquareWithPlusStack': I_SquareWithPlusStack,
  'SquareWithStripesDiagonal': I_SquareWithStripesDiagonal,
  'SquareWithTickStack': I_SquareWithTickStack,
  'SS_DEPLOYMENT_FAILED': I_SS_DEPLOYMENT_FAILED,
  'SS_DEPLOYMENT_IN_PROGRESS': I_SS_DEPLOYMENT_IN_PROGRESS,
  'SS_DEPLOYMENT_MISSING': I_SS_DEPLOYMENT_MISSING,
  'SS_DEPLOYMENT_PENDING': I_SS_DEPLOYMENT_PENDING,
  'SS_OFFLINE': I_SS_OFFLINE,
  'SS_ON_DISPATCH': I_SS_ON_DISPATCH,
  'SS_ON_MISSION': I_SS_ON_MISSION,
  'SS_ON_RIDE': I_SS_ON_RIDE,
  'SS_ONLINE': I_SS_ONLINE,
  'SS_PARTIALLY_ONLINE': I_SS_PARTIALLY_ONLINE,
  'SS_REGISTRATION_REVOKED': I_SS_REGISTRATION_REVOKED,
  'StarburstWithExlamation': I_StarburstWithExlamation,
  'Tack': I_Tack,
  'TagLeftWithMultiply': I_TagLeftWithMultiply,
  'TagLeftWithNumberSign': I_TagLeftWithNumberSign,
  'Thermometer': I_Thermometer,
  'Tick': I_Tick,
  'TicketWithTick': I_TicketWithTick,
  'TrafficLight': I_TrafficLight,
  'Triangle': I_Triangle,
  'TriangleDown': I_TriangleDown,
  'TriangleUp': I_TriangleUp,
  'TriangleUpAboveTriangleDown': I_TriangleUpAboveTriangleDown,
  'TriangleWithExclamation': I_TriangleWithExclamation,
  'TriangleWithSpeedBump': I_TriangleWithSpeedBump,
  'TurningCogs': I_TurningCogs,
  'VortexWithCar': I_VortexWithCar,
  'WindShieldWithWiperFluid': I_WindShieldWithWiperFluid,
} as const;

export const ICON_NAMES = objectKeys(ICONS);

export const isIconName = (iconName: string): iconName is IconName =>
  (ICON_NAMES as string[]).includes(iconName);
