function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
      <path
        fill="var(--c-primary, currentColor)"
        d="M17 13c-1 0-2-.2-2.9-.6L8.5 18 4 13.5 5.5 12l3 3 3.8-3.8A7 7 0 0 1 10.7 3H10A10 10 0 0 0 0 13c0 5.5 4.5 10 10 10s10-4.5 10-10v-.7a6 6 0 0 1-3 .7z"
      />
      <path
        fill="var(--c-secondary, var(--c-primary, currentColor))"
        d="M17 0c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6zm-.6 2v5h1.2V2h-1.2zm-.4 8h2V8h-2v2z"
      />
    </svg>
  );
}

export default Icon;
