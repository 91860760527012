function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16">
      <clipPath id="svg_clip_envelope_with_at_sign">
        <path d="M-1 0v16l13 .5a8.5 8.5 0 0 1 0-17L25 0v16l-13 .5a8.5 8.5 0 0 0 0-17L-1 0Z" />
      </clipPath>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        d="M1 1h22v14H1zm0 0 5 5m17-5-5 5"
        clipPath="url(#svg_clip_envelope_with_at_sign)"
      />
      <path
        fill="currentColor"
        d="M14.27 5.5v3.1c0 .83.43 1.2 1.04 1.2.91 0 1.42-.86 1.42-2.25 0-2.61-1.85-4.35-4.64-4.35-2.9 0-4.83 1.96-4.83 4.9 0 3 2 4.7 5 4.7 1.38 0 2.32-.34 3.14-.8v1.29a7.4 7.4 0 0 1-3.3.7C8.42 14 6 11.64 6 8.1 6 4.47 8.46 2 12.08 2 15.58 2 18 4.29 18 7.43c0 2.15-1.1 3.55-2.76 3.55-.97 0-1.74-.5-1.95-1.28-.42.58-1.08.86-1.84.86-1.5 0-2.48-1.1-2.48-2.6 0-1.57 1.1-2.56 2.4-2.56.64 0 1.33.22 1.72.66l.04-.57h1.14ZM13.1 7.9c0-.74-.7-1.38-1.54-1.38s-1.36.64-1.36 1.46c0 .8.53 1.46 1.36 1.46s1.54-.66 1.54-1.4V7.9Z"
      />
    </svg>
  );
}

export default Icon;
