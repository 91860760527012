function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.01 6.43H4.98V4.71h12.61l-1.36-1.83 1.38-1.03 3.4 4.58zm.18 6.67-7.2-1.18-3.63-2.22a8 8 0 0 0-4.18-1.2H.8v2.3h5v1.38H2.93v2.31h5.15v1.39h-3v2.3h6.83a3.37 3.37 0 1 1 6.74-.18 22.1 22.1 0 0 0 2.93-.35l.9-.16a.88.88 0 0 0 .7-.86v-1.2a2.38 2.38 0 0 0-2-2.33zm-5.93 7.09a2.05 2.05 0 1 0 0-4.1 2.05 2.05 0 0 0 0 4.1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
