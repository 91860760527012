function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.13 9.65h-3.06V7.76l3.06-4.15h-3.06V1.18H6.93v2.43H3.87l3.06 4.15v1.89H3.87l3.06 4.15v1.66H3.87l3.06 4.15v2.93h10.14v-2.93l3.06-4.15h-3.06V13.8zM12 19.87a2.21 2.21 0 1 1 2.21-2.2 2.2 2.2 0 0 1-2.21 2.2zm0-5.81a2.21 2.21 0 1 1 2.21-2.2 2.2 2.2 0 0 1-2.21 2.2zm0-5.8a2.21 2.21 0 1 1 0-4.42 2.21 2.21 0 0 1 0 4.42z"
      />
    </svg>
  );
}

export default Icon;
