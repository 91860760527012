function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <defs>
        <clipPath id="a">
          <path
            fill="none"
            strokeWidth="0"
            d="M32 30H0V1h32v29ZM19 18.1l1.97-10.87.03-.35a5 5 0 0 0-10 0l2 11.22c.08.9.55 1.7 1.25 2.2a4 4 0 1 0 3.5 0 3 3 0 0 0 1.24-2.2Z"
          />
        </clipPath>
      </defs>
      <path
        d="m4.25 16-.25.25V23h24v-6.75l-.25-.25H4.25zM27 26v3h4V15l-2-2h2v-2h-3l-3-5C23.5 4.5 8.5 4.5 7 6l-3 5H1v2h2l-2 2v14h4v-3h22Zm2-2H3v-8.17l2-1.95L8.44 7.5c.92-.29 3.59-.63 7.56-.63s6.64.34 7.56.63L27 13.88l2 1.95V24ZM23.8 7.59"
        clipPath="url(#a)"
      />
      <path d="M17 17.88a1 1 0 0 1-2 0l-2-11a3 3 0 0 1 6 0l-2 11Z" />
      <circle cx="16" cy="23.88" r="2" />
    </svg>
  );
}

export default Icon;
