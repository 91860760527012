function Icon() {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M10 0A10 10 0 0 0 0 10v10h10a10 10 0 1 0 0-20Zm4 11.2L10 15l-4-3.8A3 3 0 0 1 8 6a3 3 0 0 1 2 .8A3 3 0 0 1 15 9a3 3 0 0 1-1 2.2Z"
      />
    </svg>
  );
}

export default Icon;
