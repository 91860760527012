function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
      <path
        fill="currentColor"
        d="M8.5 10.3c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8-1.8.8-1.8 1.8.8 1.8 1.8 1.8zm8-2.4h-1.3a6.8 6.8 0 0 0-6.1-6.1V.5H7.9v1.3a6.6 6.6 0 0 0-6.1 6.1H.5v1.2h1.3a6.8 6.8 0 0 0 6.1 6.1v1.3h1.2v-1.3a6.8 6.8 0 0 0 6.1-6.1h1.3V7.9zm-8 4.9c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3z"
      />
    </svg>
  );
}

export default Icon;
