function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14">
      <path
        fill="currentColor"
        d="M14 3H7v2h7v3l4-4-4-4ZM0 10l4 4v-3h7V9H4V6Z"
      />
    </svg>
  );
}

export default Icon;
