function Icon() {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill="none"
        height="15"
        rx="7.5"
        stroke="currentColor"
        width="15"
        x=".5"
        y=".5"
      />
    </svg>
  );
}

export default Icon;
