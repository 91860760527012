function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M13 24c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zM24 2l-2-2-8 8-3-3-2 2 5 5L24 2zM0 21h9c0-2.3 1.8-4 4-4s4 1.7 4 4h1c2.1-.2 5-1.1 5-2v-2c0-1.4-1.6-2.8-3-3l-10-2S5 8 0 8"
      />
    </svg>
  );
}

export default Icon;
