function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M1.71 1.59v18.84l5.54-8.95 4.35 3.25 3.43-5.52-2.09-1.39 6.12-2.87-.14 6.84-2.13-1.41-4.61 7.43-4.35-3.25-4.84 7.85h19.53V1.59z"
      />
    </svg>
  );
}

export default Icon;
