function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M9.88 2.1 2.25 9.74v5.09L14.97 2.1zm4.38 19.51 7.49-7.49v-5.1L9.16 21.61zM19.27 2.1 2.25 19.13v2.48h2.61L21.75 4.72V2.1zm2.48 19.38v-4.05l-4.09 4.09zM2.25 2.14V6.2l4.09-4.1z"
      />
    </svg>
  );
}

export default Icon;
