function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.12.65a11.22 11.22 0 1 0 11.21 11.21A11.21 11.21 0 0 0 12.12.65zM6 17.12v-2.7L10.41 11a3.38 3.38 0 1 1 3.42 0l4.39 3.39v2.7z"
      />
    </svg>
  );
}

export default Icon;
