function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m7.73 17.38-3.59-3.59 3.59-3.58 1.32 1.31-2.27 2.27 2.27 2.27zm-6.66-14v17.24h21.86V3.38zM7.55 5a1.16 1.16 0 1 1 0 2.32 1.16 1.16 0 0 1 0-2.32zM4 5a1.16 1.16 0 1 1 .21 2.31A1.16 1.16 0 0 1 4 5zm17.07 13.76H2.93V8.83h18.14zm-9.02-1.29h-1.9l2-7.35h1.9zm4.18-.09-1.32-1.32 2.27-2.27-2.27-2.27 1.32-1.31 3.59 3.58z"
      />
    </svg>
  );
}

export default Icon;
