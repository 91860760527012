function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20ZM4 10.5 8.5 15 16 7.5 14.5 6l-6 6-3-3L4 10.5Z"
      />
    </svg>
  );
}

export default Icon;
