function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="m19.4 8.6-8-8a2 2 0 0 0-2.8 0l-8 8a2 2 0 0 0 0 2.8l8 8c.8.8 2 .8 2.8 0l8-8c.8-.8.8-2 0-2.8zM9 4h2v8H9V4zm2.5 12.5h-3v-3h3v3z"
      />
    </svg>
  );
}

export default Icon;
