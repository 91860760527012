function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m15.22 14.7 4.57 4.7-2.04 2.03-4.61-4.75m-2.4-6.47L9.66 9.13a4.52 4.52 0 0 0-5.31-6.38l3 3v1.84L6.08 8.88H4.26l-3-3a4.52 4.52 0 0 0 6.38 5.31l1.16 1.14M3.7 19.4 14.34 9.13a4.52 4.52 0 0 1 5.31-6.38l-3 3v1.84l1.3 1.29h1.81l3-3a4.52 4.52 0 0 1-6.38 5.31L5.73 21.43z"
      />
    </svg>
  );
}

export default Icon;
