function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M20.52 14.55 12 13.15l-4.29-2.62a9.52 9.52 0 0 0-4.95-1.42H.94v11.45h8.6a4 4 0 1 1 8-.19A26.77 26.77 0 0 0 21 20l1.07-.2a1 1 0 0 0 .83-1v-1.47a2.81 2.81 0 0 0-2.38-2.78zm-1.28-12.3a4.22 4.22 0 0 0-6 0 4.22 4.22 0 0 0 0 6l3 3 3-3a4.22 4.22 0 0 0 0-6zm-3 4.82a1.84 1.84 0 1 1 .02 0zm-2.62 15.91a2.42 2.42 0 1 0 0-4.84 2.42 2.42 0 0 0 0 4.84z"
      />
    </svg>
  );
}

export default Icon;
