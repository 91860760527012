function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M8 9.8c-2.3 0-7 1.2-7 3.5V15h14v-1.8c0-2.3-4.7-3.4-7-3.4zM12 4c0 2.2-1.8 4-4 4S4 6.2 4 4s1.8-4 4-4 4 1.8 4 4z"
      />
    </svg>
  );
}

export default Icon;
