function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="M11 1a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm-1 4v8h2V5h-2zm-.5 12.5h3v-3h-3v3z"
      />
    </svg>
  );
}

export default Icon;
