function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m11 15.47-3.82 1.31L8.54 13l9.18-9 1.57-1.55a1.76 1.76 0 0 1 2.47 0 1.75 1.75 0 0 1 0 2.48zm9.29 6.62H1.74V3.54h13l-3.23 3.2H4.94v12.15h12.15v-6.43l3.2-3.13z"
      />
    </svg>
  );
}

export default Icon;
