function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19.8 9.4c-.2 0-.5.3-.6.8v.1L13 5.4l-3-2.3-2.3-1.3c-.7-.5-1.5-1-2.4-.6S2 4.1 2 4.1.2 6.8.1 7.8c-.2 1 .4 1.6 1 2.2l1.8 2 3 2.4 6 5c-.5.2-.8.6-.6.8 0 .2.5.2 1 0l.3-.4 2.1 1.7s2 1.8 3.7 1.3c1.6-.4 2-.6 2.7-1.3l2.2-2.7c.5-.8.6-1.4.7-3 0-1.8-2-3.3-2-3.3l-2.2-1.7.2-.4c.1-.5 0-1-.2-1zM3.6 5.6l.3-.4s1.7-2.1 3-2.6L9.7 5s-1.3.7-2.8 2.7l-.2.3c-1.6 1.9-2 3.3-2 3.3l-2.8-2c0-1.5 1.8-3.6 1.8-3.6zm15 16.5c0 .2-.4.4-.8.4s-.7 0-.8-.2c0-.2.3-.4.8-.4.4 0 .7 0 .8.2zm0-5.4l-.5.7s-.6.9-2.2 2c-1.5 1-2.2.1-2.2.1l-1.8-2.1c1.2-.7 2.9-3 2.9-3l.2-.2s1.8-2.2 2.2-3.5l2.5 1.2s1 .6.3 2.3c-.6 1.7-1.4 2.5-1.4 2.5zm5-1.4c0 .4 0 .7-.2.8s-.4-.3-.4-.8c0-.4 0-.7.2-.8.2 0 .4.3.4.8z"
      />
    </svg>
  );
}

export default Icon;
