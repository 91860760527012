function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.41 13.12h-9.04v9.04h9.04zM13.37 1.84v9h9.05v-9zm4.53 6.51a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 1.84v9h9v-9zm4.49 6.51a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 13.12v9h9v-9zm4.52 6.51a2 2 0 1 1 .74-.14 2 2 0 0 1-.77.14z"
      />
    </svg>
  );
}

export default Icon;
