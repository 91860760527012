function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        d="M8 1a8 8 0 0 0-7 4 8 8 0 0 0 14 0 8 8 0 0 0-7-4Z"
      />
      <circle cx="8" cy="5" r="3" fill="currentColor" />
    </svg>
  );
}

export default Icon;
