function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m19 16.9-4.53-3.5a7.28 7.28 0 0 1-8.94 0L1 16.9v4h18zm-9-3.91A5 5 0 1 0 10 3a5 5 0 0 0 0 9.99zm13-4.4h-2.59V6h-1.82v2.59H16v1.8h2.59V13h1.82v-2.6H23z"
      />
    </svg>
  );
}

export default Icon;
