function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M23.43 11.91c0-1.82-1.36-3.5-3.72-4.68v1.88c1.32.82 2.07 1.81 2.07 2.8 0 1.38-1.44 2.75-3.78 3.65.21-1.2.32-2.43.32-3.65C18.32 5.5 15.55.48 12 .48c-1.79 0-3.46 1.33-4.64 3.64h1.88a3.59 3.59 0 0 1 2.76-2c1.38 0 2.75 1.44 3.65 3.79A20.06 20.06 0 0 0 12 5.59C5.59 5.59.57 8.37.57 11.91c0 1.8 1.34 3.48 3.67 4.66v-1.88a3.63 3.63 0 0 1-2-2.78c0-1.37 1.44-2.74 3.79-3.64a20 20 0 0 0-.33 3.64c0 6.41 2.78 11.43 6.32 11.43 1.79 0 3.45-1.32 4.63-3.62h-1.9a3.53 3.53 0 0 1-2.75 2c-1.37 0-2.74-1.44-3.64-3.78 1.2.21 2.42.32 3.64.32 6.41-.03 11.43-2.8 11.43-6.35zM9.51 14.62H8v-2.95h-.6v-.83h.79l1.36-2.33h4.92l1.38 2.33h.79v.83h-.53v2.95h-1.49v-1.21H9.51zm5.64-2.53a.53.53 0 1 0-.53.52.52.52 0 0 0 .53-.52zm-5.64.52a.52.52 0 1 0 0-1.04.52.52 0 0 0 0 1.04z"
      />
    </svg>
  );
}

export default Icon;
