function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22 3.62H7.69a1.6 1.6 0 0 0-1.24.59L1.23 10.6a1.61 1.61 0 0 0 0 2L6.45 19a1.6 1.6 0 0 0 1.24.59H22a.92.92 0 0 0 .92-.92V4.54a.92.92 0 0 0-.92-.92zm-2.9 6.72h-2.38v2.3h2.38v1.63h-2.38v2.38h-1.63v-2.38h-2.3v2.38h-1.63v-2.38H8.77v-1.63h2.39v-2.3H8.77V8.71h2.39V6.33h1.63v2.38h2.3V6.33h1.63v2.38h2.38zm-4.01 0h-2.3v2.3h2.3z"
      />
    </svg>
  );
}

export default Icon;
