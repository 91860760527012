function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="none" stroke="currentColor">
        <circle cx="16" cy="16" r="13.8" strokeWidth="2" />
        <path strokeWidth="2.5" d="M16 13v10" />
        <path strokeLinecap="round" strokeWidth="3" d="M16 10h0" />
      </g>
    </svg>
  );
}

export default Icon;
