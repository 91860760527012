function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M.89 3.53V18.9H23V3.53zm12 12.27L9 12.49l1.33-1.55 2.39 2 6.59-7.04 1.49 1.39zM7.42 8a2.24 2.24 0 1 1-4.48.08A2.24 2.24 0 0 1 7.42 8zm0 6.35a2.24 2.24 0 1 1-.16-.83c.11.28.17.57.16.87z"
      />
    </svg>
  );
}

export default Icon;
