function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16">
      <g fill="currentColor">
        <circle cx="2" cy="2" r="2" />
        <circle cx="2" cy="8" r="2" />
        <circle cx="9" cy="8" r="2" />
        <circle cx="9" cy="2" r="2" />
        <circle cx="2" cy="14" r="2" />
        <circle cx="9" cy="14" r="2" />
      </g>
    </svg>
  );
}

export default Icon;
