function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m22.94 10.76-5.24-9.2-5 9.2h3.11v1.68a3.4 3.4 0 0 1-3.4 3.4h-1.9v4h1.9a7.41 7.41 0 0 0 7.4-7.39v-1.69zM8.1 1.69H3.14v21.02H8.1z"
      />
    </svg>
  );
}

export default Icon;
