function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M8.9 12h2c-.2-1.5 1.4-2.3 2.1-3.5 2-4.7-5.3-5.9-6.5-1.6l1.9.7c.9-2.8 4.5-1 2 1.2C9.1 10 9 10.2 9 12Zm1.1 8a10 10 0 0 1-9.2-6 9.8 9.8 0 0 1-.8-4A10 10 0 0 1 6 .8a9.8 9.8 0 0 1 4-.8 10 10 0 0 1 9.2 6c.5 1.3.8 2.6.8 4a10 10 0 0 1-6 9.2 9.8 9.8 0 0 1-4 .8Zm1.4-5.6a1.4 1.4 0 1 0-2.8 0 1.4 1.4 0 0 0 2.8 0Z"
      />
    </svg>
  );
}

export default Icon;
