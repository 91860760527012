function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15.36 4.52A12 12 0 0 0 5 10.49L.71 18h22.82V4.52zm5.82 11.09H4.78l2.29-3.95A9.6 9.6 0 0 1 13.69 7v2.8h-2l3.11 4.88 3-4.88h-2V6.88h5.43z"
      />
    </svg>
  );
}

export default Icon;
