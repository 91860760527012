function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m14 11.37-.84-.64a5.19 5.19 0 1 0-5.25 0l-6.69 5.19v4.14H6.1zm4.97 3.82-4.44 4.87h5.39v-4.14zm-8.88 7.44H5.66L18.23 8.94h4.43z"
      />
    </svg>
  );
}

export default Icon;
