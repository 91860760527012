function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m18.4 19.91-4.6-3.56a3.55 3.55 0 1 0-3.6 0l-4.6 3.56v2.83h12.8zm4.12-13.25h-5.16v5.16h5.16zm-15.88 0H1.48v5.16h5.16zM14.58.97H9.42v5.16h5.16z"
      />
    </svg>
  );
}

export default Icon;
