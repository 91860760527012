function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 21">
      <path
        fill="currentColor"
        d="M11 18a2.9 2.9 0 0 1-3 3 2.9 2.9 0 0 1-3-3Zm2-11a5 5 0 0 0-4-4.9V1a.9.9 0 0 0-1-1 .9.9 0 0 0-1 1v1.1A5 5 0 0 0 3 7c0 3 0 7-3 10h16c-3-3-3-7-3-10Z"
      />
    </svg>
  );
}

export default Icon;
