function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32Zm0 28a12 12 0 1 1 0-24 12 12 0 0 1 0 24Zm0-21v9H7a9 9 0 1 0 9-9Z"
      />
    </svg>
  );
}

export default Icon;
