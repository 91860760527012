function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12 21.9a8.87 8.87 0 0 1-3.6-17v3.97a5.51 5.51 0 1 0 7.2 0V4.93a8.87 8.87 0 0 1-3.6 17zm1.68-20.24h-3.36v11.2h3.36z"
      />
    </svg>
  );
}

export default Icon;
