function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m14.33 7.51 2 2-6.08 6a4.18 4.18 0 0 0-.53-.21v-1.68H7.33v1.71A4 4 0 1 0 12 17.08l6-5.92 2 2 1.64-7.38zm-7.05 1.2h2.39l.05-1.59h2.77L8.44.74 4.56 7.12h2.77zm0 3.6h2.39l.05-2.43H7.33z"
      />
    </svg>
  );
}

export default Icon;
