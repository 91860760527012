function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M7.22 1.74H3.73v20.18h3.49zm7.43 8.58V8.59h2.72l-4.3-7.08-4.5 7.08h2.59v3.45l6.3 4.83v5.05h3.49v-6.77z"
      />
    </svg>
  );
}

export default Icon;
