function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M21 19h-2l-2-3c-1-1-11-1-12 0l-2 3H1v2h1l-1 1v9h3v-2h14v2h3v-9l-1-1h1v-2ZM3 20.99Zm.65-.09h.03-.03Zm.58.1 2.14-3.44c.62-.12 2.2-.31 4.63-.31 2.5 0 4.02.17 4.62.3L17.77 21 19 22.46V27H3v-4.45l1.23-1.54ZM19 21h-.02.02Zm-.67-.09h-.01.01ZM4 25h14v1.25H4z" />
      <circle cx="23" cy="9" r="1" />
      <g fill="none" stroke="currentColor">
        <circle cx="23" cy="9" r="7" strokeWidth="2" />
        <path strokeWidth="2" d="m23 9 3 2" />
        <path d="m21 4 2 5" />
      </g>
    </svg>
  );
}

export default Icon;
