function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M18.58 13.11a4.49 4.49 0 0 0-1.68.33l-1.83-2.91a4.86 4.86 0 1 0-6.14 0L7.1 13.44a4.49 4.49 0 1 0 2.72 5h4.36a4.48 4.48 0 1 0 4.4-5.28zm-9-8 .81-.8L12 6l1.65-1.64.81.8-1.65 1.6 1.65 1.65-.81.81L12 7.57l-1.65 1.65-.81-.81 1.65-1.65zm4.6 11.69H9.82a4.39 4.39 0 0 0-1.38-2.5l1.87-3a4.62 4.62 0 0 0 3.38 0l1.87 3a4.39 4.39 0 0 0-1.38 2.5z"
      />
    </svg>
  );
}

export default Icon;
