function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M15 14.29a.61.61 0 1 0 0 1.22.61.61 0 0 0 0-1.22zm-6 0a.61.61 0 1 0 0 1.22.61.61 0 0 0 0-1.22zm3-12.82L.64 9.62v3.67L4 10.85V21.4h15.81V10.8l3.37 2.43V9.54zm5.3 12.9h-.61v3.69H15v-1.41H9v1.41H7.24v-3.69h-.72v-1h.92L9 10.68h5.75l1.61 2.72h.91z"
      />
    </svg>
  );
}

export default Icon;
