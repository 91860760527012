function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18V4c4.4 0 8 3.6 8 8s-3.6 8-8 8z"
      />
    </svg>
  );
}

export default Icon;
