function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m12.07 2.28-10.3 7.4V13l3.06-2.2v9.57h14.34v-9.62L22.23 13V9.61z"
      />
    </svg>
  );
}

export default Icon;
