function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M4 12h12V3l-6 6-4-4 2-1 2 2 6-6H4v12Zm-2 2V4H0v12h12v-2H2Z"
      />
    </svg>
  );
}

export default Icon;
