function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="m22 12 3-3c3-3 9-9 6-9H1c-3 0 3 6 6 9l3 3c2 2 2 4 2 6v6c0 1 1 2 2 3l3 3c2 2 3 2 3 0V18c0-2 0-4 2-6z"
      />
    </svg>
  );
}

export default Icon;
