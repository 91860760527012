function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
      <g fill="none" stroke="currentColor" strokeWidth="2">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 18v-4l-4-3m-8 3h5l5-9 5 3 2-3M5 9l2-4h4"
        />
        <path d="m12 6-3 6" />
      </g>
      <circle cx="13" cy="2" r="2" fill="currentColor" />
    </svg>
  );
}

export default Icon;
