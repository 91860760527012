function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <g fill="none" stroke="currentColor">
        <path strokeWidth="3" d="m29 15-8-8M21 15l8-8" />
        <g strokeLinecap="round">
          <path
            strokeDasharray="0 0 1.6 4.4"
            strokeWidth="3"
            d="M15 30c7 0 13-7 10-13"
          />
          <path
            strokeDasharray="0 0 2.6 3"
            strokeWidth="2"
            d="M20 6C16 3 5 3 0 4"
          />
        </g>
      </g>
      <path d="M11 15a6 6 0 0 1 6 6c0 4-6 9-6 9s-6-5-6-9a6 6 0 0 1 6-6Zm0 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
    </svg>
  );
}

export default Icon;
