function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.14 2.06 1.2 9.92v3.54l3.25-2.35v10.16h5.64v-5.69h4.21v5.69h5.38V11.06l3.25 2.34V9.84z"
      />
    </svg>
  );
}

export default Icon;
