function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
      <clipPath id="SVGID_00000156579022342133822960000014713945343767586194_">
        <path d="M2 11H0V0h13L2 11zm14 1V1h-1L4 12h12z" />
      </clipPath>
      <path
        fill="currentColor"
        clipPath="url(#SVGID_00000156579022342133822960000014713945343767586194_)"
        d="m15.8 4.2-1.4 1.4a9.3 9.3 0 0 0-12.8 0L.2 4.2a11 11 0 0 1 15.6 0zM8 5c-1.9 0-3.7.8-5 2.1l1.4 1.4C5.4 7.6 6.6 7 8 7a5 5 0 0 1 3.5 1.5l1.4-1.4A6.7 6.7 0 0 0 8 5zm0 4c-.8 0-1.6.3-2.1.9L8 12l2.1-2.1C9.6 9.3 8.8 9 8 9z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="m12 1-9 9"
      />
    </svg>
  );
}

export default Icon;
