function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 9">
      <path
        fill="currentColor"
        d="M8 9H0V0h8v9ZM6.5 1H1v1h5.5V1ZM4 3H1v1h3V3Zm2.85 1.85-.7-.7L3.5 6.79 2.35 5.65l-.7.7L3.5 8.21l3.35-3.36Z"
      />
    </svg>
  );
}

export default Icon;
