function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12 9.83c-1.47 0-2.89.23-4.22.66v2.85c0 .35-.2.67-.51.82a10.55 10.55 0 0 0-2.44 1.7.9.9 0 0 1-.64.25.91.91 0 0 1-.65-.26l-2.27-2.28a.88.88 0 0 1-.27-.64c0-.25.1-.48.27-.65A15.56 15.56 0 0 1 12 8c4.16 0 7.94 1.63 10.73 4.28a.91.91 0 0 1 .27.65.9.9 0 0 1-.27.65l-2.27 2.28a.91.91 0 0 1-.65.26.94.94 0 0 1-.64-.26 10.33 10.33 0 0 0-2.45-1.7.91.91 0 0 1-.51-.82V10.5A13.3 13.3 0 0 0 12 9.83z"
      />
    </svg>
  );
}

export default Icon;
