function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M22.43 10.46a2 2 0 0 0-1-.31 1.75 1.75 0 0 0-.4 0A1.84 1.84 0 0 0 19.5 12v3.24c0 .39.13.77.36 1.08.08.1.16.21.26.3l.06.05.27.18h.09l.29.11h.11a1.84 1.84 0 0 0 2.12-1.09c.1-.22.14-.47.14-.71V12a1.87 1.87 0 0 0-.77-1.54zm-18.71 0a1.93 1.93 0 0 0-1-.31 1.84 1.84 0 0 0-.41 0A1.84 1.84 0 0 0 .8 12v3.24a1.8 1.8 0 0 0 .62 1.38l.06.05.26.18h.1l.28.11h.11a2 2 0 0 0 .42.05 1.83 1.83 0 0 0 1.85-1.85V12a1.87 1.87 0 0 0-.78-1.54zM20.9 8.69v-.87a5.58 5.58 0 0 0-5.57-5.57H8.8a5.58 5.58 0 0 0-5.57 5.57v.82a3.19 3.19 0 0 1 2.65 3.13v3.59a3.19 3.19 0 0 1-.94 2.26 8.19 8.19 0 0 0 13.78.7 10.06 10.06 0 0 1-4.44.77h-.1a1.43 1.43 0 0 1-1.29.8h-1.28a1.43 1.43 0 0 1 0-2.86h1.28a1.43 1.43 0 0 1 1.34.93 9.93 9.93 0 0 0 4.4-.91 3.12 3.12 0 0 1-.46-1.62v-3.6a3.19 3.19 0 0 1 1.06-2.36 8.22 8.22 0 0 0-14.33 0V7.82a3.91 3.91 0 0 1 3.9-3.9h6.53a3.91 3.91 0 0 1 3.91 3.9v1.65a3.13 3.13 0 0 1 1.66-.78z"
      />
    </svg>
  );
}

export default Icon;
