function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M2 7.45V3.5h20.1v4zM2 14v-4h20.1v4zm0 6.5v-3.95h20.1v3.95z"
      />
    </svg>
  );
}

export default Icon;
